import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Gravatar from 'react-gravatar';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  AppBar,
  Grid,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Tooltip,
  MenuItem,
  Hidden,
  styled,
  Button,
  Drawer,
  Divider,
  CircularProgress
} from '@mui/material';
import {
  KeyboardArrowDown,
  ArrowBack,
  Close,
  AttachFile,
  RemoveRedEyeOutlined
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';

import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import logo from '../../src/assets/fullLogo.svg';
import smallLogo from '../../src/assets/BLogo.svg';

import TabPanel from './tabs';
import { Colors } from '../config/default';
import { userRoles, topNav } from '../constants/appConstants';
import DocumentComments from './documentComments';
import EmailVendor from './emailVendor';
import { get_fitered_obj, get_tabs } from '../redux/actions/action';
import MuiPopover from './muiPopover';
import { signOut } from '../services/services';
import MuiModal from './muiModel';
import ActivityDrawer from './activityDrawer';
import Prompt from './prompt';
import { defaultFilter } from '../services/common';

const settings = ['Profile', 'Users', 'Logout'];
const menuSeetings = ['Profile', 'Logout'];
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    backgroundColor: '#282828',
    marginTop: theme.spacing(3),
    border: '.3px solid #1E1E1E',
    minWidth: 160,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '.5em'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      },
      '&:hover': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      }
    }
  }
}));
function TopNav({
  username,
  activeTab,
  setActiveTab,
  currentTabs,
  path,
  userRole,
  filteredData,
  recordExist,
  handleClear,
  show,
  buttonArray,
  submitData,
  id,
  docName,
  padding,
  isDrawerOpen,
  approvers,
  setImages,
  annotationType,
  isAttachmentSearched,
  handleClearAttachmentSearch,
  deliveryTicketUpload,
  setRefId,
  handleClearErpSearch,
  refId,
  invoiceNumber,
  vendor,
  po,
  date,
  images,
  lineItem,
  lineItemsData,
  setIndex,
  openComment,
  setOpenComment,
  getAppliedFilters
}) {
  const { ADMIN, OWNER, ACCOUNTANT, VENDOR } = userRoles;
  const midScreen = useMediaQuery('(min-width:600px) and (max-width:880px)');
  const smallScreen = useMediaQuery('(max-width:600px)');
  const { BACK, VIEW_DOC } = topNav;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [logoutIndex, setLogoutIndex] = useState(2);
  const poMatchPermission = userDetails?.user?.poMatchPermission;
  const [loadingButtons, setLoadingButtons] = useState({});
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(open);
  };

  const Tabs = {
    activeTab: userDetails?.role === ADMIN ? 'Documents' : 'Dashboard',
    usersActiveTab: 'All Users',
    documentChildActiveTab: 'To Review',
    IntegrationChildActiveTab: 'My Integration'
  };
  const menuItemClickHandlers = [
    () => {
      navigate('/userProfile');
    },
    () => {
      navigate('/users');
    },
    async () => {
      await signOut();
      dispatch(
        get_tabs({
          activeTab: '',
          documentChildActiveTab: ''
        })
      );
      dispatch(get_fitered_obj(defaultFilter));
      sessionStorage.clear();
      navigate('/');
    }
  ];
  const menuItemHandler = [
    () => {
      navigate('/userProfile');
    },

    async () => {
      await signOut();
      dispatch(
        get_tabs({
          activeTab: '',
          documentChildActiveTab: ''
        })
      );
      dispatch(get_fitered_obj(defaultFilter));
      sessionStorage.clear();
      navigate('/');
    }
  ];
  const handleButtonClick = async (e, status) => {
    setLoadingButtons((prev) => ({ ...prev, [status]: true }));
    try {
      await submitData(e, status, 'main');
    } finally {
      setLoadingButtons((prev) => ({ ...prev, [status]: false }));
    }
  };
  const handleOpen = (idx) => {
    setLogoutIndex(idx);
    setOpenPrompt(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        sx={{
          boxShadow: '0',
          padding: { xs: '0 0rem', md: padding ? '0 4em 0 0' : '0 4rem' },
          height: '4.2rem',
          width: '100vw',
          overflow: { xs: 'auto', md: 'hidden' }
        }}>
        <Container maxWidth="xxl">
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            {path === 'canvas' ? (
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  sx={{
                    border: `1px solid ${Colors.DARKBLUE}`,
                    borderRadius: '10px',
                    height: '2rem',
                    marginRight: { xs: '10px', sm: 0 },
                    width: { xs: '1rem', md: '6rem' },
                    padding: { xs: 0, sm: 'auto' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '.8em',
                    textTransform: 'none',
                    color: Colors.WHITE,
                    '&:hover': {
                      background: 'none',
                      border: `1px solid ${Colors.DARKBLUE}`
                    }
                  }}
                  onClick={() => {
                    if (userDetails?.role === ADMIN) {
                      navigate('/admin');
                    } else if (userDetails?.role === VENDOR) {
                      navigate('/vendor');
                    } else {
                      navigate('/home');
                    }
                  }}>
                  <ArrowBack
                    sx={{
                      color: Colors.DARKBLUE,
                      fontSize: '1.4em',
                      mr: '.3em'
                    }}
                  />
                  {smallScreen ? '' : BACK}
                </Button>
                <Typography
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                    ml: '1em',
                    textTransform: 'uppercase'
                  }}>
                  {docName?.length > 15
                    ? `${docName.substring(0, 12)}...${docName.substring(
                        docName.lastIndexOf('.') - 1
                      )}`
                    : docName}{' '}
                </Typography>
              </Grid>
            ) : (
              <>
                <Hidden mdDown>
                  <img
                    src={logo}
                    alt="beiingHuman"
                    style={{ height: '2em', cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(get_tabs(Tabs));
                      userDetails?.role === ADMIN
                        ? navigate('/admin')
                        : userDetails?.role === VENDOR
                        ? navigate('/vendor')
                        : navigate('/home');
                    }}
                  />
                </Hidden>
                <Hidden mdUp>
                  <img
                    src={smallLogo}
                    alt="beiingHuman"
                    style={{ height: '2em', cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(get_tabs(Tabs));
                      userDetails?.role === ADMIN
                        ? navigate('/admin')
                        : userDetails?.role === VENDOR
                        ? navigate('/vendor')
                        : navigate('/home');
                    }}
                  />
                </Hidden>
              </>
            )}

            {path === 'canvas' || midScreen || smallScreen ? (
              <Grid sx={{ height: { xs: '', md: '4.5rem' } }}></Grid>
            ) : (
              <TabPanel
                tabs={currentTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                filteredData={filteredData}
                recordExist={recordExist}
                handleClear={handleClear}
                tab="parent"
                path={path}
                handleClearAttachmentSearch={handleClearAttachmentSearch}
                isAttachmentSearched={isAttachmentSearched}
                handleClearErpSearch={handleClearErpSearch}
                getAppliedFilters={getAppliedFilters}
              />
            )}

            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              {path === 'canvas' && (
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <ActivityDrawer type="canvas" id={id} documentType={annotationType} />
                  {(annotationType === 'Invoice' ||
                    annotationType === 'Receipt' ||
                    annotationType === 'G702') &&
                    (deliveryTicketUpload || poMatchPermission) && (
                      <MuiModal
                        text={VIEW_DOC}
                        icon={<RemoveRedEyeOutlined sx={{ fontSize: '20px' }} />}
                        show="supportingDoc"
                        id={refId}
                        po={po}
                        LargeScreenWidth="70vw"
                        XLScreenWidth="47vw"
                        images={images}
                        annotationType={annotationType}
                        deliveryTicketUpload={deliveryTicketUpload}
                        poMatchPermission={poMatchPermission}
                        lineItem={lineItem}
                        lineItemsData={lineItemsData}
                      />
                    )}

                  {annotationType === 'Invoice' && deliveryTicketUpload && (
                    <MuiModal
                      text="Remove/Attach Delivery Ticket ( Only approved delivery tickets can be attached )"
                      icon={<AttachFile sx={{ fontSize: '20px' }} />}
                      show="deliveryTicket"
                      setImages={setImages}
                      setRefId={setRefId}
                      id={refId}
                      vendor={vendor}
                      po={po}
                      invoiceNumber={invoiceNumber}
                      date={date}
                      LargeScreenWidth="58vw"
                      setIndex={setIndex}
                    />
                  )}

                  {userDetails?.role === OWNER || userDetails?.role === ACCOUNTANT
                    ? ''
                    : buttonArray?.map((btn) => {
                        const isLoading = loadingButtons[btn?.status];
                        return (
                          <Button
                            disabled={isLoading}
                            onMouseEnter={btn?.name === 'Reject' ? handlePopoverOpen : undefined}
                            onMouseLeave={btn?.name === 'Reject' ? handlePopoverClose : undefined}
                            variant="outlined"
                            sx={{
                              border: `1px solid ${btn?.Color}`,
                              borderRadius: '10px',
                              height: '2rem',
                              width: '7rem',
                              marginRight: '1rem',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '.8em',
                              textTransform: 'none',
                              color: Colors.WHITE,
                              '&:hover': {
                                background: 'none',
                                border: `1px solid ${btn?.Color}`
                              }
                            }}
                            startIcon={isLoading ? null : btn?.icon}
                            onClick={(e) => handleButtonClick(e, btn.status)}>
                            {isLoading ? (
                              <CircularProgress size={16} sx={{ color: btn?.Color }} />
                            ) : (
                              btn?.name
                            )}
                          </Button>
                        );
                      })}
                  <MuiPopover
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    startMessage="Rejected"
                    approvers={
                      approvers?.approved?.length > 0
                        ? approvers?.approved[approvers?.approved?.length - 1]
                        : []
                    }
                    startPosition="bottom"
                    endPosition="top"
                  />
                  <EmailVendor documentName={docName} />
                  <DocumentComments
                    isDrawerOpen={isDrawerOpen}
                    id={id}
                    open={openComment}
                    setOpen={setOpenComment}
                  />
                </Grid>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Gravatar
                  email={userDetails?.user?.email}
                  style={{
                    order: '2px solid #1AACAC',
                    height: '2em',
                    width: '2em',
                    borderRadius: '50%'
                  }}
                />
                <StyledMenu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}>
                  {path === 'owner' || show === 'showUser'
                    ? settings.map((setting, index) => (
                        <MenuItem
                          sx={{ width: '150px', borderRadius: '10px' }}
                          key={setting}
                          onClick={() => {
                            ((path === 'profile' || path === 'canvas') &&
                              userDetails?.role === OWNER &&
                              index === 2) ||
                            (path === 'canvas' &&
                              index === 1 &&
                              userDetails?.role !== OWNER &&
                              userDetails?.role !== ADMIN)
                              ? handleOpen(index)
                              : menuItemClickHandlers[index]();
                          }}>
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))
                    : menuSeetings.map((setting, index) => (
                        <MenuItem
                          sx={{ width: '150px', borderRadius: '10px' }}
                          key={setting}
                          onClick={() => {
                            path === 'canvas' &&
                            index === 1 &&
                            userDetails?.role !== OWNER &&
                            userDetails?.role !== ADMIN
                              ? handleOpen(index)
                              : menuItemHandler[index]();
                          }}>
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                  <Prompt
                    title="Logout"
                    description="Are you sure you want to proceed? You might lose the changes you've made."
                    leftButton="Cancel"
                    rightButton="Confirm"
                    setOpen={setOpenPrompt}
                    open={openPrompt}
                    opacity={false}
                    handleClick={() =>
                      path === 'owner' || show === 'showUser'
                        ? menuItemClickHandlers[logoutIndex]()
                        : menuItemHandler[logoutIndex]()
                    }
                  />
                </StyledMenu>
              </Box>

              <Grid
                item
                sx={{
                  display: 'flex',
                  marginX: '.5rem',
                  alignItems: 'flex-start',
                  color: 'secndary.light'
                }}>
                <Grid
                  item
                  sx={{
                    width: 'max-content',
                    display: path === 'canvas' ? { xs: 'none', sm: 'block' } : 'block'
                  }}>
                  <Typography sx={{ fontSize: '.9em' }}>{username}</Typography>
                  <Typography sx={{ fontSize: '.7em', color: Colors.TEXTGREY }}>
                    {userRole === OWNER ? 'AP Person' : userDetails?.user?.name}
                  </Typography>
                </Grid>
                <Tooltip title="Open settings">
                  <IconButton sx={{ p: 0, color: 'secndary.light' }} onClick={handleOpenUserMenu}>
                    <KeyboardArrowDown />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {midScreen ? (
        path === 'canvas' ? (
          ''
        ) : (
          <AppBar
            position="static"
            sx={{
              boxShadow: '0',
              height: '4.2rem',
              backgroundColor: Colors.NAV_BLACK
            }}>
            <Container maxWidth="xxl">
              <Toolbar
                disableGutters
                sx={{ padding: '0em', display: 'flex', justifyContent: 'center' }}>
                <TabPanel
                  tabs={currentTabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  filteredData={filteredData}
                  recordExist={recordExist}
                  handleClear={handleClear}
                  tab="parent"
                  path={path}
                  handleClearAttachmentSearch={handleClearAttachmentSearch}
                  isAttachmentSearched={isAttachmentSearched}
                  handleClearErpSearch={handleClearErpSearch}
                  getAppliedFilters={getAppliedFilters}
                />
              </Toolbar>
            </Container>
          </AppBar>
        )
      ) : (
        ''
      )}
      {smallScreen ? (
        path === 'canvas' ? (
          ''
        ) : (
          <AppBar
            position="static"
            sx={{
              boxShadow: '0',
              height: '3.5rem',
              backgroundColor: Colors.NAV_BLACK
            }}>
            <Toolbar
              sx={{
                padding: '0em',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}>
                <MenuIcon sx={{ fontSize: '26px' }} />
              </IconButton>

              <Drawer
                anchor="right"
                variant="temporary"
                open={open}
                PaperProps={{ onClick: toggleDrawer(false) }}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}>
                <Box sx={{ backgroundColor: Colors.NAV_BLACK, height: '100vh' }}>
                  <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', m: 0 }}>
                    <IconButton sx={{ mb: 2 }}>
                      <Close onClick={toggleDrawer(false)} />
                    </IconButton>
                  </Grid>
                  <Divider sx={{ mb: 2 }} />

                  <TabPanel
                    orientation={true}
                    tabs={currentTabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    filteredData={filteredData}
                    recordExist={recordExist}
                    handleClear={handleClear}
                    tab="parent"
                    path={path}
                    handleClearAttachmentSearch={handleClearAttachmentSearch}
                    isAttachmentSearched={isAttachmentSearched}
                    handleClearErpSearch={handleClearErpSearch}
                    getAppliedFilters={getAppliedFilters}
                  />
                </Box>
              </Drawer>
            </Toolbar>
          </AppBar>
        )
      ) : (
        ''
      )}
    </ThemeProvider>
  );
}
export default TopNav;
