import * as React from 'react';

import { Drawer, IconButton, Button, Grid, Typography } from '@mui/material';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { Colors } from '../config/default';
import ActivityCard from './activityCard';
import { HtmlTooltip } from '../services/common';
import { accountDetailsComponent, documentStatusText } from '../constants/appConstants';
import ApproversCard from './approversCard';

export default function ActivityDrawer({ type, id, disable, documentType }) {
  const { SHOW_ACTIVITY } = accountDetailsComponent;
  const { DUPLICATE, PENDING } = documentStatusText;
  const [state, setState] = React.useState({
    right: false
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Grid
      sx={{
        alignItems: 'center',
        height: '100vh',
        backgroundColor: Colors.NAV_BLACK,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '.3em',
          height: '0em',
          borderRadius: '20px'
        }
      }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <ActivityCard id={id} documentType={documentType} />
      <ApproversCard id={id} />
    </Grid>
  );
  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          {type === 'document' || type === 'canvas' ? (
            <HtmlTooltip
              title={
                <Typography color="inherit" sx={{ fontSize: '1em' }}>
                  {SHOW_ACTIVITY}
                </Typography>
              }>
              <span>
                <IconButton
                  sx={{
                    border: type === 'canvas' ? '.5px solid #2E97A7' : 'none',
                    mr: type === 'canvas' ? '0.5rem' : 'auto'
                  }}
                  variant={type === 'canvas' ? 'outlined' : 'standard'}
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleDrawer(anchor, true)(event);
                  }}
                  aria-label="activity"
                  disabled={disable === PENDING || disable === DUPLICATE}>
                  <ViewTimelineOutlinedIcon
                    sx={{
                      fontSize: '0.8em',
                      color:
                        disable === PENDING || disable === DUPLICATE || type === 'canvas'
                          ? Colors.WHITE
                          : Colors.DARKBLUE
                    }}
                  />
                </IconButton>
              </span>
            </HtmlTooltip>
          ) : (
            <Button
              variant="Outlined"
              sx={{
                border: '1px solid ',
                borderColor: Colors.DARKBLUE,
                height: '2rem',
                textTransform: 'none',
                color: Colors.WHITE,
                borderRadius: '10px',
                '&:hover': { backgroundColor: 'transparent' }
              }}
              onClick={toggleDrawer(anchor, true)}>
              <ArrowBackIosNewIcon sx={{ color: Colors.DARKBLUE, fontSize: '1em', mr: '.3em' }} />
              <Typography sx={{ fontSize: '1em' }}>{SHOW_ACTIVITY}</Typography>
            </Button>
          )}

          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
