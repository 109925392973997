import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, Typography, Drawer, Box } from '@mui/material';
import {
  ArrowRightAlt,
  AttachMoney,
  ShoppingCart,
  DescriptionOutlined,
  RequestQuoteOutlined,
  InsertDriveFileOutlined,
  Close
} from '@mui/icons-material';

import { Colors } from '../config/default';
import { generatePo } from '../constants/appConstants';
import { replaceName } from '../services/common';

export default function LineitemsDrawer({ lineitems, isConstructionCompany, annotationType }) {
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const serviceAndEquipmentPermission = userDetails?.company?.serviceCodeAndEquipmentNo;
  const glDivisionPermission = userDetails?.company?.glDivisionPermission;
  let filteredArray = lineitems;
  if (annotationType === 'Estimate' || annotationType === 'DeliveryTicket') {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter(
        (item) =>
          item.name !== 'userCostId' &&
          item.name !== 'userPhases' &&
          item.name !== 'userJobId' &&
          item.name !== 'userCostType' &&
          item.name !== 'GlAccount'
      );
    });
  }
  if (
    !isConstructionCompany &&
    annotationType !== 'Estimate' &&
    annotationType !== 'DeliveryTicket'
  ) {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter(
        (item) =>
          item.name !== 'userCostId' &&
          item.name !== 'userPhases' &&
          item.name !== 'userJobId' &&
          item.name !== 'userCostType'
      );
    });
  }
  if (annotationType === 'DeliveryTicket') {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter((item) => item.name !== 'Amount' && item.name !== 'Unit_Price');
    });
  }
  if (erpType === 'DeltekComputerEase' || erpType === 'QuickBooksOnline') {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter((item) => item.name !== 'userCostId');
    });
  }
  if (erpType === 'QuickBooksOnline' || erpType === 'QuickBooksDesktop') {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter((item) => item.name !== 'userPhases');
    });
  }
  if (
    !serviceAndEquipmentPermission ||
    erpType !== 'Foundation' ||
    (erpType === 'Foundation' && annotationType !== 'Invoice')
  ) {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter((item) => item.name !== 'equipmentNo' && item.name !== 'serviceCode');
    });
  }
  if (!glDivisionPermission) {
    filteredArray = filteredArray?.map((subArray) => {
      return subArray.filter((item) => item.name !== 'glDivision');
    });
  }
  const rearrangeArrays = (arrayOfArrays) => {
    const referenceStructure = arrayOfArrays[0]?.map((item) => item?.name);
    return arrayOfArrays?.map((array) =>
      referenceStructure?.map(
        (name) => array?.find((item) => item?.name === name) || { name, value: '-' } // Use a default if not found
      )
    );
  };

  const rearrangedArray = rearrangeArrays(filteredArray);

  const [state, setState] = useState({
    bottom: false
  });

  const { SHOW_LINEITEMS } = generatePo;

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const iconMap = {
    Description: DescriptionOutlined,
    Unit_Price: AttachMoney,
    Quantity: ShoppingCart,
    Amount: AttachMoney,
    Product_Code: DescriptionOutlined,
    userCostId: RequestQuoteOutlined,
    userCostType: RequestQuoteOutlined,
    userPhases: InsertDriveFileOutlined,
    userJobId: InsertDriveFileOutlined,
    GlAccount: DescriptionOutlined,
    equipmentNo: InsertDriveFileOutlined,
    serviceCode: DescriptionOutlined,
    glDivision: RequestQuoteOutlined
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'bottom' ? 'auto' : 250,
        backgroundColor: Colors.BG_BLACK
      }}
      role="presentation">
      <Grid
        container
        sx={{
          fontWeight: 'bold',
          fontSize: '0.80em',
          color: Colors.WHITE,
          backgroundColor: Colors.GREY,
          p: '1em 1em',
          mb: '8px'
        }}>
        <Close
          onClick={toggleDrawer(anchor, false)}
          sx={{
            cursor: 'pointer',
            fontSize: '1.5em',
            color: Colors.LIGHTGREY,
            position: 'absolute',
            right: 5
          }}
        />
        {rearrangedArray?.slice(0, 1)?.map((itemsArray) => (
          <>
            {itemsArray?.map((item) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1
                }}>
                <span>
                  {iconMap[item?.name]
                    ? React.createElement(iconMap[item.name], {
                        style: { fontSize: '1.2rem', color: Colors.TOURQUISE }
                      })
                    : null}
                </span>
                <span>{replaceName(item?.name, erpType)}</span>
              </div>
            ))}
          </>
        ))}
      </Grid>
      <Grid
        sx={{
          height: '9.5em',
          overflowY: 'auto'
        }}>
        {rearrangedArray?.map((items, index) => (
          <Grid
            container
            key={index}
            sx={{
              fontSize: '0.80em',
              mb: '1em',
              p: '1em 1em',
              color: Colors.TEXTGREY
            }}>
            {items?.map((item) => (
              <span
                style={{
                  flex: 1
                }}>
                {item?.value || '\u00A0\u00A0-'}
              </span>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
  return (
    <>
      <div style={{ float: 'right', marginTop: '3%' }}>
        {['bottom'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Typography
              sx={{
                color: Colors.LIGHTBLUE,
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={toggleDrawer(anchor, true)}>
              {SHOW_LINEITEMS}
              <ArrowRightAlt sx={{ color: Colors.LIGHTBLUE }} />
            </Typography>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
