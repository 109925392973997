import React, { useState } from 'react';

import { Drawer, IconButton, Typography } from '@mui/material';

import { Colors } from '../config/default';
import { HtmlTooltip } from '../services/common';
import { FilterAlt, RemoveRedEye } from '@mui/icons-material';

import SyncedDataList from './SyncedDataList';
import Filters from './filters';

export default function CustomDrawer({
  heading,
  type,
  count,
  show,
  amount,
  setAmount,
  recieivedAtDate,
  setRecieivedAtDate,
  byEmail,
  setByEmail,
  recieivedFromDate,
  setRecieivedFromDate,
  permission,
  setPermission,
  selectedOptions,
  setSelectedOptions,
  getAppliedFilters,
  searchTiltle,
  byPaymentStatus,
  setByPaymentStatus
}) {
  const [state, setState] = useState({
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) =>
    show === 'filters' ? (
      <Filters
        toggleDrawer={toggleDrawer}
        anchor={anchor}
        amount={amount}
        setAmount={setAmount}
        recieivedAtDate={recieivedAtDate}
        setRecieivedAtDate={setRecieivedAtDate}
        byEmail={byEmail}
        setByEmail={setByEmail}
        recieivedFromDate={recieivedFromDate}
        setRecieivedFromDate={setRecieivedFromDate}
        permission={permission}
        setPermission={setPermission}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        getAppliedFilters={getAppliedFilters}
        byPaymentStatus={byPaymentStatus}
        setByPaymentStatus={setByPaymentStatus}
      />
    ) : (
      <SyncedDataList
        heading={heading}
        type={type}
        toggleDrawer={toggleDrawer}
        anchor={anchor}
        searchTiltle={searchTiltle}
      />
    );
  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <HtmlTooltip
            title={
              <Typography color="inherit" sx={{ fontSize: '1em' }}>
                {show === 'filters' ? 'Filter Documents' : 'View List'}
              </Typography>
            }>
            <span>
              {show === 'filters' ? (
                <FilterAlt
                  sx={{ color: Colors.WHITE, marginTop: '.3rem', cursor: 'pointer' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleDrawer(anchor, true)(event);
                  }}
                />
              ) : (
                <IconButton
                  disabled={count === 0 || count === '0'}
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleDrawer(anchor, true)(event);
                  }}
                  aria-label="activity">
                  <RemoveRedEye
                    sx={{
                      fontSize: '1.2rem',
                      color: count === 0 || count === '0' ? Colors.DISABLEDGRAY : Colors.TOURQUISE
                    }}
                  />
                </IconButton>
              )}
            </span>
          </HtmlTooltip>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            ModalProps={{
              BackdropProps: { style: { backgroundColor: 'transparent' } }
            }}
            onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
