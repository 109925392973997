import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  Paper,
  Typography
} from '@mui/material';
import { Check, Clear, Error } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';

import { Colors } from '../config/default';
import { HtmlTooltip } from '../services/common';

export default function AccountsInput({
  label,
  state,
  setState,
  data,
  setPermission,
  permission,
  allowInput,
  setValueChanged,
  stateName,
  checkStateName,
  validateAccountDetails,
  name,
  setValidationErrors,
  getUpdatedApproversAccordingtoBasePolicy,
  policyBase,
  annotationType
}) {
  const [accountCode, setAccountCode] = useState(permission);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const isConstructionCompany = userDetails?.user?.constructionCompany;
  const [isError, setIsError] = useState(
    validateAccountDetails(permission, name, annotationType, erpType, isConstructionCompany)
  );

  const handleChange = (e, value) => {
    setValueChanged(true);
    if (stateName && stateName !== '') {
      setPermission((prev) => ({ ...prev, [stateName]: value }));
    } else {
      setPermission(value);
    }
    setAccountCode(value);
    if (name === 'JobId' && policyBase === 'jobId') {
      getUpdatedApproversAccordingtoBasePolicy(value);
    }
  };
  useEffect(() => {
    setAccountCode(permission);
  }, [permission]);
  useEffect(() => {
    const validation = validateAccountDetails(accountCode, name);
    setIsError(validation);
    if (!validation.status) {
      setValidationErrors((prev) => ({ ...prev, [name]: validation.message }));
    } else {
      setValidationErrors((prev) => {
        const { [name]: removedError, ...rest } = prev;
        return rest;
      });
    }
  }, [accountCode, name, setValidationErrors, permission]);

  const useStyles = makeStyles(() => ({
    option: {
      borderRadius: '7px',
      '&:hover': {
        borderRadius: '7px',
        backgroundColor: '#141414 !important'
      },
      '&:active': {
        borderRadius: '7px'
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className="value-button">
      <Box className="box-style">
        <p className="paragraph-style">
          <Check
            sx={{
              fontSize: '1.5em',
              marginRight: '.5rem',
              color: state === true ? Colors.DARKBLUE : ''
            }}
            onClick={() => {
              if (checkStateName && checkStateName !== '') {
                setState((prev) => ({ ...prev, [checkStateName]: !state }));
              } else {
                setState(!state);
              }
            }}
          />
          {label}
        </p>
      </Box>
      <div style={{ display: 'flex', marginBottom: '.5rem' }}>
        {data && data?.length > 0 && !data?.includes(null) ? (
          <div className={!isError?.status ? 'dropdown-with-error' : 'first-input'}>
            <div>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                <Autocomplete
                  value={permission || null}
                  onChange={handleChange}
                  options={data}
                  renderOption={(props, option) => {
                    const { key } = props;
                    return (
                      <li {...props} key={props['data-option-index'] + '-' + key}>
                        {option}
                      </li>
                    );
                  }}
                  clearIcon={<Clear size="small" sx={{ color: Colors.WHITE, fontSize: '1rem' }} />}
                  disableClearable={!permission}
                  classes={{ option: classes.option }}
                  ListboxProps={{
                    className: 'myCustomList'
                  }}
                  onKeyDown={(event) => {
                    const filteredOptions = data?.filter((option) =>
                      option?.toLowerCase().includes(event.target.value?.toLowerCase() || '')
                    );
                    if (event.key === 'Tab' && !isEmpty(filteredOptions)) {
                      const firstOption = filteredOptions[0];
                      handleChange(null, firstOption);
                    }
                  }}
                  sx={{
                    '& .MuiAutocomplete-popupIndicator': {
                      color: Colors.WHITE
                    }
                  }}
                  PaperComponent={({ children }) => (
                    <Paper
                      style={{
                        backgroundColor: Colors.NAV_BLACK,
                        color: Colors.WHITE,
                        borderRadius: '10px',
                        padding: '0px 8px'
                      }}>
                      {children}
                    </Paper>
                  )}
                  renderInput={(params) => {
                    return (
                      <TextField
                        onBlur={(e) => {
                          if (name === 'JobId' && policyBase === 'jobId') {
                            getUpdatedApproversAccordingtoBasePolicy(e.target.value);
                          }
                          if (allowInput) {
                            setValueChanged(true);
                            const newValue = e.target.value;
                            if (stateName && stateName !== '') {
                              setPermission((prev) => ({ ...prev, [stateName]: newValue }));
                            } else {
                              setPermission(newValue);
                            }
                            setAccountCode(newValue);
                          }
                        }}
                        value={permission}
                        sx={{
                          marginTop: { lg: '.7rem' }
                        }}
                        {...params}
                        variant="standard"
                        label=""
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            color: Colors.WHITE,
                            fontSize: '13px'
                          }
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </div>
          </div>
        ) : (
          <div className={!isError?.status ? 'dropdown-with-error' : 'first-input'}>
            <input
              style={{ marginTop: '.5rem' }}
              className="input-field"
              value={accountCode}
              onChange={(e) => {
                setValueChanged(true);
                setAccountCode(e.target.value);
              }}
              onBlur={(e) => {
                if (name === 'JobId' && policyBase === 'jobId')
                  getUpdatedApproversAccordingtoBasePolicy(e.target.value);

                if (stateName && stateName !== '') {
                  setPermission((prev) => ({ ...prev, [stateName]: accountCode }));
                } else {
                  setPermission(accountCode);
                }
              }}
            />
          </div>
        )}
        {!isError?.status && (
          <HtmlTooltip
            title={
              <Typography color="inherit" sx={{ fontSize: '1em' }}>
                {isError?.message}
              </Typography>
            }>
            <Error
              sx={{
                color: Colors.RED,
                marginTop: '.4rem'
              }}
            />
          </HtmlTooltip>
        )}
      </div>
    </div>
  );
}
