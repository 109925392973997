import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { documentStatusText, tableComponent } from '../constants/appConstants';
import theme from '../theme';
import { Colors } from '../config/default';
import CheckboxGroup from './checkBoxGroup';
import CustomTextField from './textfield';
import DateRangePickerWithButtons from './DatePicker';
import Btn from './button';
import { get_fitered_obj } from '../redux/actions/action';
import { defaultFilter } from '../services/common';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({}) => ({
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
  />
))(({}) => ({
  backgroundColor: Colors.NAV_BLACK,
  borderBottom: '1px solid #A7A7A7',
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: 'rotate(90deg)'
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: '1em'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));
const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(({ theme }) => ({
  variants: [
    {
      props: { checked: true },
      style: {
        '.MuiFormControlLabel-label': {
          color: theme.palette.primary.main
        }
      }
    }
  ]
}));
function Filters({
  toggleDrawer,
  anchor,
  amount,
  setAmount,
  setRecieivedAtDate,
  recieivedAtDate,
  setSelectedOptions,
  selectedOptions,
  byEmail,
  setByEmail,
  recieivedFromDate,
  setRecieivedFromDate,
  setByPaymentStatus,
  byPaymentStatus,
  permission,
  setPermission,
  getAppliedFilters
}) {
  const {
    APPROVED,
    PENDING,
    REJECT,
    WAITING,
    ERROR,
    ONHOLD,
    POPULATED,
    DUPLICATE,
    ATTACHED,
    GENERATED,
    PROCESSING
  } = documentStatusText;
  const { ONHOLD_DOC, REJECTED_DOC, PENDING_DOC, IN_REVIEW, VERFIED, VERIFIED } = tableComponent;
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState('');
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
    let checked = false;
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }
  const statusArray = [
    { label: PROCESSING, value: PENDING },
    { label: ONHOLD_DOC, value: ONHOLD },
    { label: REJECTED_DOC, value: REJECT },
    { label: APPROVED, value: APPROVED },
    { label: PENDING_DOC, value: POPULATED },
    { label: IN_REVIEW, value: WAITING },
    { label: ERROR, value: ERROR },
    { label: VERFIED, value: VERIFIED },
    { label: ATTACHED, value: GENERATED },
    { label: DUPLICATE, value: DUPLICATE }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Grid
        sx={{
          alignItems: 'center',
          height: '100vh',
          backgroundColor: Colors.NAV_BLACK,
          width: { xs: '20em', xl: '30em' },
          padding: '.5em 1em',
          overflowY: 'scroll'
        }}>
        <>
          <Grid container sx={{ mt: '.7em', mb: '.7em', justifyContent: 'flex-end' }}>
            <Close
              onClick={toggleDrawer(anchor, false)}
              sx={{ cursor: 'pointer', fontSize: '1.5em', color: Colors.LIGHTGREY }}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ color: Colors.TOURQUISE, fontSize: '1.2em', mb: '1em', ml: '1em' }}>
              Filters
            </Typography>
          </Grid>
          <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary>
                <Typography component="span">Status</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CheckboxGroup
                  data={statusArray}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <Typography component="span">Amount</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ marginTop: '1rem' }}>
                  <CustomTextField
                    value={amount?.min}
                    setValue={(val) => setAmount((prev) => ({ ...prev, min: val }))}
                    placeHolder="Min"
                    type="number"
                  />
                </div>
                <div style={{ marginTop: '1rem' }}>
                  <CustomTextField
                    value={amount?.max}
                    setValue={(val) => setAmount((prev) => ({ ...prev, max: val }))}
                    placeHolder="Max"
                    type="number"
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                <Typography component="span">Received At</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ marginTop: '1rem' }}>
                <DateRangePickerWithButtons
                  startDate={recieivedFromDate}
                  endDate={recieivedAtDate}
                  setStartDate={setRecieivedFromDate}
                  setEndDate={setRecieivedAtDate}
                  toShow="filter"
                />
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                <Typography component="span">Category</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ marginTop: '.5rem' }}>
                <CheckboxGroup
                  data={[{ label: 'By Email', value: true }]}
                  selectedOptions={byEmail}
                  setSelectedOptions={setByEmail}
                  toShow="email"
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                <Typography component="span">Sort By</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ marginTop: '.5rem' }}>
                <RadioGroup
                  name="use-radio-group"
                  value={permission}
                  onChange={(event) => {
                    setPermission(event.target.value);
                  }}>
                  <MyFormControlLabel
                    value="Ascending"
                    label="Old to new"
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '18px'
                          }
                        }}
                      />
                    }
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '14px'
                      }
                    }}
                  />
                  <MyFormControlLabel
                    value="Descending"
                    label="New to old"
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '18px'
                          }
                        }}
                      />
                    }
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '14px'
                      }
                    }}
                  />
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
            {userRole !== 'Vendor' && (
              <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <Typography component="span">Payment Status</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginTop: '.5rem' }}>
                  <RadioGroup
                    name="use-radio-group"
                    value={byPaymentStatus}
                    onChange={(event) => {
                      setByPaymentStatus(event.target.value);
                    }}>
                    <MyFormControlLabel
                      value="Paid"
                      label="Paid"
                      control={
                        <Radio
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: '18px'
                            }
                          }}
                        />
                      }
                      sx={{
                        '& .MuiTypography-root': {
                          fontSize: '14px'
                        }
                      }}
                    />
                    <MyFormControlLabel
                      value="Unpaid"
                      label="Unpaid"
                      control={
                        <Radio
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: '18px'
                            }
                          }}
                        />
                      }
                      sx={{
                        '& .MuiTypography-root': {
                          fontSize: '14px'
                        }
                      }}
                    />
                  </RadioGroup>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
          <Grid container sx={{ justifyContent: 'flex-end' }}>
            <Btn
              disabled={getAppliedFilters()?.length === 0}
              marginTop="1rem"
              width="5rem"
              variant="contained"
              text="Clear"
              height="2rem"
              onClick={() => {
                setSelectedOptions([]);
                setByEmail(false);
                setAmount({
                  min: '',
                  max: ''
                });
                setRecieivedAtDate('');
                setRecieivedFromDate('');
                setByPaymentStatus('');
                setPermission('');
                dispatch(get_fitered_obj(defaultFilter));
              }}
            />
          </Grid>
        </>
      </Grid>
    </ThemeProvider>
  );
}

export default Filters;
