import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { Button, Grid, CircularProgress, Typography, Box } from '@mui/material';
import {
  FileUploadOutlined,
  InsertPageBreak,
  Sync,
  FileUpload,
  Add,
  FileDownload,
  Download
} from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';

import theme from '../theme';
import TabPanel from './tabs';
import UploadFileButton from './uploadFileButton';
import { Colors } from '../config/default';
import {
  dropzoneData,
  AdminTable,
  TooltipMessages,
  ConstructionCompanySyncArray,
  UserSyncArray,
  userRoles,
  tableComponent
} from '../constants/appConstants';
import SearchBar from './searchBar';
import {
  downloadCSV,
  downloadFile,
  getReport,
  deleteDocument,
  deleteBatchDoc
} from '../services/services';
import UploadManualFile from './uploadManualFile';
import { HtmlTooltip } from '../services/common';
import MuiModal from './muiModel';
import { useToast } from '../toast/toastContext';
import DownloadFile from './downloadFile';
import CustomSelect from './customSelect';
import CustomDrawer from './customDrawer';
import Prompt from './prompt';

function HeaderSection({
  currentTabs,
  setActiveTab,
  activeTab,
  intervalCall,
  checkedIds,
  setCheckedIds,
  filteredData,
  recordExist,
  setRecordExist,
  tab,
  integrationChildTab,
  handleSyncErp,
  erpLoading,
  currentUser,
  searchProducts,
  setSearchProducts,
  getAllVendors,
  getUsers,
  getManualUser,
  dataAccordingTabs,
  searchAttachments,
  setSearchAttachments,
  isAttachmentSearched,
  handleClearAttachmentSearch,
  searchedResult,
  handleSearchAttachment,
  uploadAttachment,
  handleClearErpSearch,
  searchedCheques,
  isChequeSearched,
  chequeNum,
  handleSearchFromErp,
  setChequeNum,
  selectedRowsHasPO,
  documentCount,
  allQueuesData,
  setFilteredData,
  setGetDocument,
  amount,
  setAmount,
  recieivedAtDate,
  setRecieivedAtDate,
  byEmail,
  setByEmail,
  recieivedFromDate,
  setRecieivedFromDate,
  permission,
  setPermission,
  selectedOptions,
  setSelectedOptions,
  getAppliedFilters,
  handleFilterandSearchClear,
  selectedRowsHasReview,
  splitDocIds,
  getbatches,
  byPaymentStatus,
  setByPaymentStatus
}) {
  const navigate = useNavigate();
  const [uploadDocScreen, setUploadDocScreen] = useState(false);
  const [uploadPdfScreen, setUploadPdfScreen] = useState(false);
  const [loadingCheckbox, setLoadingCheckbox] = useState(false);
  const [downloadFiles, setDownloadFiles] = useState(false);
  const [downloadAll, setDownloadAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const isConstructionCompany = userDetails?.user?.constructionCompany;
  const SyncArray = isConstructionCompany ? ConstructionCompanySyncArray : UserSyncArray;
  const erpType = userDetails?.user?.erpType;
  const { showToast } = useToast();
  const [open, setOpen] = useState(false);
  const { SYNC, EXPORTABLE_FILE, SYNC_USER, DOWNLOAD_REPORT, HELPER_FOR_REPORT } = AdminTable;
  const { INFO, SPLIT_PDF } = dropzoneData;
  const { ADMIN, VENDOR, OWNER, ACCOUNTANT } = userRoles;
  const { DELETE_DOCUMENTS, DELETE_SELECTED_DOCUMENTS, DELETE } = tableComponent;
  const {
    SPLIT_PDF_MESSAGE,
    SYNC_DATA,
    MANUAL_UPLOAD,
    MANUAL_DOWNLOAD,
    UPLOAD_FILE_MESSAGE,
    SEARCH_MESSAGE,
    SEARCH_ATTACHMENT_MESSAGE,
    DOWNLOAD_FILES,
    DOWNLOAD_ALL_FILES
  } = TooltipMessages;
  const docSplitterPermission = useSelector(
    (state) => state?.userInfo?.userInfo?.user?.docSpliterPermission
  );
  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const searchedData = sessionStorage.getItem('searchedKey');

  const handleClear = () => {
    setSearchProducts('');
    sessionStorage?.setItem('searchedKey', '');
    setFilteredData([]);
    setGetDocument((prev) => ({
      ...prev,
      search: false
    }));
    setRecordExist(false);
    setCheckedIds([]);
  };

  useEffect(() => {
    if (searchedData && searchedData !== '' && searchedData !== 'undefined') {
      setGetDocument((prev) => ({
        ...prev,
        search: true
      }));
      setSearchProducts(searchedData);
      allQueuesData(searchedData);
    }
  }, []);
  const DownloadDocument = (s3FileName, signedUrl) => {
    const link = document.createElement('a');
    link.href = signedUrl;
    link.setAttribute('download', s3FileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCsvDownload = async () => {
    setLoadingCheckbox(true);
    const params = {
      documentIds: checkedIds,
      erpType: erpType
    };
    const response = await downloadCSV(params);
    if (response?.status === 200) {
      const files = response?.data?.payload?.data;
      if (files?.length === 1) {
        const file = files[0];
        window.open(file.signedUrl, '_blank');
      } else {
        const zip = new JSZip();
        await Promise?.all(
          files?.map(async (file) => {
            const fileBlob = await fetch(file?.signedUrl).then((res) => res?.blob());
            zip?.file(file?.s3_file_name, fileBlob);
          })
        );
        const zipBlob = await zip?.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'downloaded_files.zip');
      }
      setCheckedIds([]);
      intervalCall();
    } else if (response?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(
        response?.data?.metadata?.message || response?.response?.data?.metadata?.message,
        'error'
      );
    }
    setLoadingCheckbox(false);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const handleSyncClose = (event, selectedValue) => {
    setAnchorEl(null);
    if (typeof selectedValue !== 'string') {
      handleSyncErp(activeIntegration, selectedValue);
    }
  };

  const extractDocumentIds = () => {
    return dataAccordingTabs?.map((doc) => doc?._id);
  };
  const handleDownloadAllFiles = (e) => {
    setDownloadAll(true);
    const documentIds = extractDocumentIds();
    handleDownloadFiles(e, documentIds);
  };
  const handleDownloadFiles = async (e, ids) => {
    const params = {
      documentIds: ids
    };
    const response = await downloadFile(params);
    if (response?.status === 200) {
      if (response?.data?.payload?.data?.length === 1) {
        response?.data?.payload?.data?.forEach((file) => {
          DownloadDocument(file?.s3_file_name, file?.signedUrl);
        });
      } else {
        const zip = new JSZip();
        await Promise?.all(
          response?.data?.payload?.data?.map(async (file) => {
            const fileBlob = await fetch(file?.signedUrl).then((res) => res?.blob());
            zip?.file(file?.s3_file_name, fileBlob);
          })
        );
        const zipBlob = await zip?.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'downloaded_files.zip');
      }
      setCheckedIds([]);
    } else if (response?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(
        response?.data?.metadata?.message || response?.response?.data?.metadata?.message,
        'error'
      );
    }
    setDownloadFiles(false);
    setDownloadAll(false);
  };
  const downloadReport = async () => {
    setDownloading(true);
    const response = await getReport();
    if (response?.status === 200) {
      window.open(response?.data?.payload?.data?.signedUrl, '_blank');
    } else if (response?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(
        response?.data?.metadata?.message || response?.response?.data?.metadata?.message,
        'error'
      );
    }
    setDownloading(false);
  };
  const handleSearch = () => {
    setGetDocument((prev) => ({
      ...prev,
      search: searchProducts && searchProducts !== '' && searchProducts !== 'undefined'
    }));
  };
  const deleteDocs = async () => {
    setDeleting(true);
    const params = {
      documentIds: checkedIds
    };
    const result = await deleteDocument(params);
    if (result?.status === 200) {
      allQueuesData(searchedData);
      showToast(result?.data?.metadata?.message, 'success');
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setDeleting(false);
    setOpenPrompt(false);
  };

  const deleteSplitDocs = async () => {
    setDeleting(true);
    const params = {
      docIds: splitDocIds
    };
    const result = await deleteBatchDoc(params);
    if (result?.status === 200) {
      getbatches();
      allQueuesData(searchedData);
      showToast(result?.data?.metadata?.message, 'success');
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setDeleting(false);
    setOpenPrompt(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          borderBottom: { xs: 'none', md: '2px solid #2A2A2A' },
          alignItems: { lg: 'center' },
          justifyContent: tab === 'attachments' && !uploadAttachment ? 'flex-end' : 'space-between',
          height: { xs: 'max-content', lg: '3.3rem' },
          paddingX: { xs: '1em', lg: '2rem' }
        }}>
        {tab === 'attachments' ? (
          uploadAttachment ? (
            <Box sx={{ mt: { xs: '1rem', lg: '0rem' } }}>
              <Typography sx={{ color: Colors.WHITE, fontSize: '1rem' }}>
                Add Attachments
              </Typography>
            </Box>
          ) : (
            ''
          )
        ) : tab === 'documents' ? (
          <TabPanel
            tabs={currentTabs}
            peddingBottom="1.5rem"
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            filteredData={filteredData}
            recordExist={recordExist}
            handleClear={handleFilterandSearchClear}
            tab="child"
            setCheckedIds={setCheckedIds}
            handleClearErpSearch={handleClearErpSearch}
            getAppliedFilters={getAppliedFilters}
          />
        ) : (
          <TabPanel
            tabs={currentTabs}
            peddingBottom="1.5rem"
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setCheckedIds={setCheckedIds}
            handleClearErpSearch={handleClearErpSearch}
          />
        )}

        {tab === 'documents' ? (
          <Grid
            container
            item
            xs={12}
            lg={7}
            sx={{
              gap: { xs: '8px', sm: '3px' },
              height: { xs: 'max-content', md: '3.3rem' },
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', lg: '0' },
              marginBottom: '.8rem'
            }}>
            {(!isEmpty(filteredData) || recordExist) &&
              isEmpty(checkedIds) &&
              currentUser !== 'Vendor' && (
                <>
                  <HtmlTooltip
                    title={
                      <Typography color="inherit" sx={{ fontSize: '1em' }}>
                        {DOWNLOAD_ALL_FILES}
                      </Typography>
                    }>
                    <span>
                      <Button
                        disabled={downloadFiles || isEmpty(dataAccordingTabs) || downloadAll}
                        variant="outlined"
                        sx={{
                          textTransform: 'unset !important',
                          marginRight: '1rem',
                          marginLeft: '.5rem',
                          width: '7.5rem',
                          height: '2.2rem',
                          color: Colors.WHITE,
                          borderRadius: '10px',
                          fontSize: '.875rem',
                          borderRadius: '10px',
                          '&:hover': {
                            borderColor: Colors.DEEPGREY
                          }
                        }}
                        onClick={handleDownloadAllFiles}>
                        {downloadAll ? (
                          <CircularProgress size={22} sx={{ color: Colors.TEXTGREY }} />
                        ) : (
                          'Download All'
                        )}
                      </Button>
                    </span>
                  </HtmlTooltip>
                </>
              )}
            {(currentUser === 'Customer' || currentUser === 'Accountant') &&
              isEmpty(checkedIds) && (
                <>
                  {docSplitterPermission ? (
                    <UploadFileButton
                      icon={
                        <InsertPageBreak
                          sx={{
                            display: { xs: 'none', sm: 'block' },
                            marginRight: '.3rem',
                            color: Colors.RED,
                            fontSize: '1.3em'
                          }}
                        />
                      }
                      text={SPLIT_PDF}
                      borderColor={Colors.RED}
                      uploadDocScreen={uploadDocScreen}
                      uploadPdfScreen={uploadPdfScreen}
                      setUploadDocScreen={setUploadDocScreen}
                      setUploadPdfScreen={setUploadPdfScreen}
                      setOpen={setOpen}
                      tooltipText={SPLIT_PDF_MESSAGE}
                      open={open}
                      onClick={() => {
                        setOpen(true);
                        setUploadDocScreen(false);
                        setUploadPdfScreen(true);
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <UploadFileButton
                    icon={
                      <FileUploadOutlined
                        sx={{
                          display: { xs: 'none', sm: 'block' },
                          marginRight: '.1rem',
                          color: 'secondary.light',
                          fontSize: '1.5em'
                        }}
                      />
                    }
                    text={INFO}
                    uploadDocScreen={uploadDocScreen}
                    uploadPdfScreen={uploadPdfScreen}
                    setUploadDocScreen={setUploadDocScreen}
                    setUploadPdfScreen={setUploadPdfScreen}
                    tooltipText={UPLOAD_FILE_MESSAGE}
                    setOpen={setOpen}
                    open={open}
                    onClick={() => {
                      setOpen(true);
                      setUploadPdfScreen(false);
                      setUploadDocScreen(true);
                    }}
                  />
                </>
              )}

            {((currentUser === 'Customer' || currentUser === 'Accountant') &&
              isEmpty(checkedIds)) ||
            currentUser === 'Vendor' ? (
              ''
            ) : (
              <>
                {!isEmpty(checkedIds) && (userRole === OWNER || userRole === ACCOUNTANT) && (
                  <>
                    <Button
                      disabled={isEmpty(checkedIds) || deleting}
                      sx={{
                        textTransform: 'unset !important',
                        marginRight: '.5rem',
                        color: Colors.WHITE,
                        height: '2.2rem',
                        width: '4.8rem',
                        fontSize: '.875rem',
                        borderRadius: '10px'
                      }}
                      variant="outlined"
                      onClick={() => setOpenPrompt(true)}>
                      {DELETE}
                    </Button>
                    <Prompt
                      title={DELETE_DOCUMENTS}
                      description={DELETE_SELECTED_DOCUMENTS}
                      leftButton="Cancel"
                      rightButton="Delete"
                      setOpen={setOpenPrompt}
                      open={openPrompt}
                      opacity={true}
                      handleClick={deleteDocs}
                      loading={deleting}
                    />
                  </>
                )}
                {!isEmpty(checkedIds) && (
                  <HtmlTooltip
                    title={
                      <Typography color="inherit" sx={{ fontSize: '1em' }}>
                        {DOWNLOAD_FILES}
                      </Typography>
                    }>
                    <span>
                      <Button
                        disabled={isEmpty(checkedIds) || downloadFiles}
                        variant="outlined"
                        sx={{
                          textTransform: 'unset !important',
                          marginRight: '.5rem',
                          width: '8.5rem',
                          height: '2.2rem',
                          color: Colors.WHITE,
                          borderRadius: '10px',
                          fontSize: '.875rem',
                          borderRadius: '10px',
                          '&:hover': {
                            borderColor: Colors.DEEPGREY
                          }
                        }}
                        onClick={(e) => {
                          handleDownloadFiles(e, checkedIds);
                          setDownloadFiles(true);
                        }}>
                        {downloadFiles ? (
                          <CircularProgress size={22} sx={{ color: Colors.TEXTGREY }} />
                        ) : (
                          'Download Files'
                        )}
                      </Button>
                    </span>
                  </HtmlTooltip>
                )}
                {!selectedRowsHasPO && (
                  <Button
                    disabled={isEmpty(checkedIds) || loadingCheckbox}
                    sx={{
                      textTransform: 'unset !important',
                      marginRight: '.5rem',
                      color: Colors.WHITE,
                      height: '2.2rem',
                      width: '10rem',
                      fontSize: '.875rem',
                      borderRadius: '10px'
                    }}
                    variant="outlined"
                    onClick={handleCsvDownload}>
                    {loadingCheckbox ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : (
                      <>
                        <Download
                          sx={{
                            fontSize: '18px',
                            marginRight: '.2rem',
                            color:
                              isEmpty(checkedIds) || loadingCheckbox ? 'gray' : Colors.TOURQUISE
                          }}
                        />
                        {EXPORTABLE_FILE}
                      </>
                    )}
                  </Button>
                )}
              </>
            )}
            {activeTab !== 'Classify/Split' ? (
              <>
                <SearchBar
                  width="230px"
                  setSearchProducts={setSearchProducts}
                  text="Search Documents..."
                  searchProducts={searchProducts}
                  tooltipText={SEARCH_MESSAGE}
                  handleKeyPress={handleSearch}
                  handleClear={handleClear}
                  recordExist={recordExist}
                  filteredData={filteredData}
                />
                <CustomDrawer
                  amount={amount}
                  setAmount={setAmount}
                  recieivedAtDate={recieivedAtDate}
                  setRecieivedAtDate={setRecieivedAtDate}
                  byEmail={byEmail}
                  setByEmail={setByEmail}
                  recieivedFromDate={recieivedFromDate}
                  setRecieivedFromDate={setRecieivedFromDate}
                  permission={permission}
                  setPermission={setPermission}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  getAppliedFilters={getAppliedFilters}
                  byPaymentStatus={byPaymentStatus}
                  setByPaymentStatus={setByPaymentStatus}
                  show="filters"
                />
              </>
            ) : (
              (userRole === OWNER || userRole === ACCOUNTANT) && (
                <>
                  <Button
                    disabled={isEmpty(splitDocIds) || deleting}
                    sx={{
                      textTransform: 'unset !important',
                      marginRight: '.5rem',
                      color: Colors.WHITE,
                      height: '2.2rem',
                      width: '7rem',
                      fontSize: '.875rem',
                      borderRadius: '10px'
                    }}
                    variant="outlined"
                    onClick={() => setOpenPrompt(true)}>
                    {DELETE}
                  </Button>
                  <Prompt
                    title={DELETE_DOCUMENTS}
                    description={DELETE_SELECTED_DOCUMENTS}
                    leftButton="Cancel"
                    rightButton="Delete"
                    setOpen={setOpenPrompt}
                    open={openPrompt}
                    opacity={true}
                    handleClick={deleteSplitDocs}
                    loading={deleting}
                  />
                </>
              )
            )}
          </Grid>
        ) : tab === 'attachments' ? (
          <Box sx={{ mt: { xs: '1rem', lg: '0rem' } }}>
            <SearchBar
              width="600px"
              setSearchProducts={setSearchAttachments}
              text="Search Attachment..."
              searchProducts={searchAttachments}
              tooltipText={SEARCH_ATTACHMENT_MESSAGE}
              handleKeyPress={handleSearchAttachment}
              handleClear={handleClearAttachmentSearch}
              recordExist={isAttachmentSearched}
              filteredData={searchedResult}
            />
          </Box>
        ) : tab === 'Dashboard' && userRole !== ADMIN && userRole !== VENDOR ? (
          <Grid
            container
            item
            xs={12}
            lg={7.5}
            sx={{
              gap: { xs: '8px', sm: '3px' },
              height: { xs: 'max-content', md: '3.3rem' },
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', lg: '0' },
              marginBottom: '.8rem'
            }}>
            <HtmlTooltip
              title={
                <Typography color="inherit" sx={{ fontSize: '1em' }}>
                  {HELPER_FOR_REPORT}
                </Typography>
              }>
              <Button
                disabled={
                  documentCount?.totalDocuments === documentCount?.Approved ||
                  documentCount?.totalDocuments === 0 ||
                  downloading
                }
                sx={{
                  textTransform: 'unset !important',
                  marginRight: '1rem',
                  color: Colors.WHITE,
                  height: '2.2rem',
                  width: '5.5rem',
                  fontSize: '.875rem',
                  borderRadius: '10px'
                }}
                variant="outlined"
                onClick={downloadReport}>
                {downloading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  <>
                    <Download
                      sx={{
                        fontSize: '18px',
                        marginRight: '.2rem',
                        color:
                          documentCount?.totalDocuments === documentCount?.Approved ||
                          documentCount?.totalDocuments === 0
                            ? 'gray'
                            : Colors.TOURQUISE
                      }}
                    />
                    {DOWNLOAD_REPORT}
                  </>
                )}
              </Button>
            </HtmlTooltip>
          </Grid>
        ) : tab === 'Users' && userRole !== ADMIN ? (
          <Grid
            item
            sx={{
              display: 'flex',
              height: { xs: 'max-content', md: '3.3rem' },
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', md: '0' }
            }}>
            {erpType === 'Foundation' && (
              <>
                <MuiModal
                  text="Queue"
                  width="5.2rem"
                  show="QBD Queue"
                  mr=".5rem"
                  color={Colors.TOURQUISE}
                />
                <CustomSelect
                  disabled={activeIntegration === ''}
                  text={SYNC}
                  handleClose={handleSyncClose}
                  setAnchorEl={setAnchorEl}
                  anchorEl={anchorEl}
                  options={SyncArray}
                  height="2.25rem"
                  width="50%"
                  backgroundColor="none"
                  variant="outlined"
                  loadingCheckbox={erpLoading}
                  show="syncCall"
                />
              </>
            )}

            <MuiModal
              text="Add User"
              show="Add_Users"
              btnHeight="2rem"
              width="8rem"
              smallScreenWidth="70%"
              mediumScreenWidth="50%"
              getUsers={getUsers}
              icon={
                <Add sx={{ color: Colors.DEEPBLUE, fontSize: '1.5em', marginRight: '.2rem' }} />
              }
            />
          </Grid>
        ) : tab === 'integration' && integrationChildTab === 'My Integration' ? (
          <Grid
            item
            sx={{
              display: 'flex',
              height: { xs: 'max-content', md: '3.3rem' },
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', sm: '0em' }
            }}>
            {erpType !== 'Foundation' ? ( // Added This to show Dropdown for Foundation
              <Button
                disabled={(activeIntegration === '' && erpType !== 'RentalMan') || erpLoading}
                sx={{
                  textTransform: 'unset !important',
                  height: '2.5em',
                  color: Colors.WHITE,
                  borderRadius: '10px',
                  width: '6rem',
                  borderColor: Colors.DEEPBLUE,
                  '&:hover': {
                    borderColor: Colors.DEEPBLUE
                  }
                }}
                variant="outlined"
                onClick={() => handleSyncErp(activeIntegration)}>
                {erpLoading ? (
                  <CircularProgress size={22} color="inherit" />
                ) : (
                  <HtmlTooltip
                    title={
                      <Typography color="inherit" sx={{ fontSize: '1em' }}>
                        {SYNC_DATA}
                      </Typography>
                    }>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      <Sync
                        sx={{ color: Colors.DEEPBLUE, fontSize: '1.4em', marginRight: '.3em' }}
                      />
                      {SYNC}
                    </Box>
                  </HtmlTooltip>
                )}
              </Button>
            ) : (
              <>
                {/* <MuiModal   // Enable it for QBO when needed
                  text="Queue"
                  width="5.2rem"
                  show="QBD Queue"
                  mr=".5rem"
                  color={Colors.TOURQUISE}
                /> */}
                <CustomSelect
                  disabled={activeIntegration === ''}
                  text={SYNC}
                  handleClose={handleSyncClose}
                  setAnchorEl={setAnchorEl}
                  anchorEl={anchorEl}
                  options={SyncArray}
                  height="2.25rem"
                  width="50%"
                  backgroundColor="none"
                  variant="outlined"
                  loadingCheckbox={erpLoading}
                  show="syncCall"
                />
              </>
            )}
          </Grid>
        ) : tab === 'integration' && integrationChildTab === 'Manual Syncing' ? (
          <Grid
            item
            sx={{
              display: 'flex',
              gap: { xs: '1em', md: '0' },
              height: { xs: 'max-content', md: '3.3rem' },
              justifyContent: 'flex-end'
            }}>
            <DownloadFile
              tooltipText={MANUAL_DOWNLOAD}
              width="9rem"
              text={'Sample Files'}
              height="2.2rem"
              icon={
                <FileDownload
                  sx={{ color: Colors.DEEPBLUE, fontSize: '1.5em', marginRight: '.2rem' }}
                />
              }
            />
            <UploadManualFile
              handleClose={handleClose}
              setAnchorEl={setAnchorEl}
              loadingCheckbox={loadingCheckbox}
              tooltipText={MANUAL_UPLOAD}
              anchorEl={anchorEl}
              width="9rem"
              text={INFO}
              height="2.2rem"
              icon={
                <FileUpload
                  sx={{ color: Colors.DEEPBLUE, fontSize: '1.5em', marginRight: '.2rem' }}
                />
              }
            />
          </Grid>
        ) : tab === 'integration' && integrationChildTab === 'Search Integration' ? (
          <Box sx={{ mt: { xs: '1rem', lg: '0rem' }, mb: '1.5rem' }}>
            <SearchBar
              width="600px"
              setSearchProducts={setChequeNum}
              text="Search Invoices by check Number..."
              searchProducts={chequeNum}
              tooltipText="You can Search Invoices by check Number"
              handleKeyPress={handleSearchFromErp}
              handleClear={handleClearErpSearch}
              recordExist={isChequeSearched}
              filteredData={searchedCheques}
            />
          </Box>
        ) : activeTab === 'Manual Users' && userRole === ADMIN ? (
          <Grid
            item
            sx={{
              height: { xs: 'max-content', md: '3.3rem' },
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', md: '0' }
            }}>
            <MuiModal
              text="Invite User"
              width="8rem"
              show="manual users"
              getManualUser={getManualUser}
            />
          </Grid>
        ) : activeTab === 'All Vendors' && userRole === ADMIN ? (
          <Grid
            item
            sx={{
              height: { xs: 'max-content', md: '3.3rem' },
              justifyContent: 'flex-end',
              marginTop: { xs: '1em', md: '0' }
            }}>
            <MuiModal
              text="Add Vendor"
              width="8rem"
              show="register vendor"
              getAllVendors={getAllVendors}
            />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </ThemeProvider>
  );
}

export default HeaderSection;
