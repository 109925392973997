import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { isEmpty, isEqual } from 'lodash';

import { Grid, CircularProgress, Chip, Typography, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  CallReceivedOutlined,
  DescriptionOutlined,
  InsertDriveFileOutlined,
  HighlightAltOutlined,
  DeleteOutlineOutlined
} from '@mui/icons-material';

import Layout from '../components/layout';
import DataTable from '../components/dataTable';
import theme from '../theme';
import HeaderSection from '../components/headerSection';
import {
  getParentTabs,
  getChildTabs,
  featuresTabs,
  dashboardTabs,
  formatDate,
  formatDateOnly,
  constructionData,
  serviceData,
  replaceStatus,
  defaultFilter,
  getIntegrationTabs,
  HtmlTooltip
} from '../services/common';
import {
  getSelectedDocs,
  syncData,
  getUserInfo,
  createCsv,
  searchAttachmentById,
  searchbyChequeNumber,
  getAllBatches,
  deleteBatchDoc
} from '../services/services';
import { get_queue, user_information, get_tabs, get_fitered_obj } from '../redux/actions/action';
import MyIntegerations from '../components/myIntegration';
import ManualSync from './manualSync';
import Policy from './policy';
import Dashboard from './dashboard';
import { useToast } from '../toast/toastContext';
import Attachments from './attachments';
import UploadAttachments from './uploadAttachments';
import ErpDocuments from './erpDocuments';
import AdminDataTable from '../components/adminDataTable';
import MuiModal from '../components/muiModel';
import { tableComponent } from '../constants/appConstants';
import { Colors } from '../config/default';
import { userRoles } from '../constants/appConstants';
import Prompt from '../components/prompt';

function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { OWNER, ACCOUNTANT, MANAGER, CLEVEL } = userRoles;
  const {
    RECIEVED_AT,
    NO_OF_INVOICES,
    FILE_NAME,
    ACTION,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_MESSAGE
  } = tableComponent;
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const savedFilters = useSelector((state) => state?.filters?.filters);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;
  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const erpType = userDetails?.user?.erpType;
  const serviceAndEquipmentPermission = userDetails?.company?.serviceCodeAndEquipmentNo;
  const childTabs = getChildTabs(userRole);
  const integrationTabs = getIntegrationTabs(erpType, activeIntegration);
  const parentActiveTab = globalActiveTab?.activeTab;
  const parentTabs = getParentTabs(userRole);
  const [activeTab, setActiveTab] = useState(parentActiveTab || parentTabs[0]?.name);
  const [childActiveTab, setChildActiveTab] = useState(
    globalActiveTab?.documentChildActiveTab || childTabs[0]?.name
  );
  const [integrationChildTab, setIntegrationChildTab] = useState(
    globalActiveTab?.IntegrationChildActiveTab || integrationTabs[0]?.name
  );
  const [featuresChildTab, setFeaturesChildTab] = useState(featuresTabs[0]?.name);
  const [dashboardChildTabs, setDashboardChildTabs] = useState(dashboardTabs[0]?.name);
  const [searchAttachments, setSearchAttachments] = useState('');
  const [createCsvLoading, setCreateCsvLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [recordExist, setRecordExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pendingQueues = useSelector((state) => state?.queue?.queue[0]?.documents);
  const [erpLoading, setErpLoading] = useState(false);
  const searchedData = sessionStorage.getItem('searchedKey');
  const [searchProducts, setSearchProducts] = useState(searchedData || '');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dataAccordingTabs, setDataAccordingTabs] = useState([]);
  const { showToast } = useToast();
  const [isAttachmentSearched, setIsAttachmentSearched] = useState(false);
  const [searchedResult, setSearchedResult] = useState([]);
  const isSearched = sessionStorage?.getItem('instrumentId');
  const [uploadAttachment, setUploadAttachment] = useState(
    !isEmpty(isSearched) || isSearched !== '' ? false : true
  );
  const [instrumentId, setInstumentId] = useState('');
  const [isChequeLoading, setIsChequeLoading] = useState(false);
  const [searchedCheques, setSearchedCheques] = useState([]);
  const [chequeNum, setChequeNum] = useState('');
  const [isChequeSearched, setIsChequeSearched] = useState(false);
  const [selectedRowsHasPO, setSelectedRowsHasPO] = useState(true);
  const [selectedRowsHasReview, setSelectedRowsHasReview] = useState(true);
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const [syncElement, setSyncElement] = useState([]);
  const [batchDocumentList, setBatchDocumentList] = useState([]);
  const [batchDocs, setBatchDocs] = useState([]);
  const [batchDocsLoading, setBatchDocsLoading] = useState(false);
  const [documentCount, setDocumentCount] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const [splitDocIds, setSplitDocIds] = useState([]);

  const formatedFilterObject = isEmpty(savedFilters)
    ? {
        search: false,
        filter: false,
        sort: '',
        createdAt: { to: '', from: '' },
        amount: { min: '', max: '' },
        sendByEmail: false,
        status: []
      }
    : savedFilters;
  const [getDocument, setGetDocument] = useState(formatedFilterObject);
  const getDocumentRef = useRef(getDocument);
  const [syncing, setSyncing] = useState(false);
  //filter states;
  const [selectedOptions, setSelectedOptions] = useState(savedFilters?.status || []);
  const [byEmail, setByEmail] = useState(savedFilters?.sendByEmail || false);
  const [byPaymentStatus, setByPaymentStatus] = useState(savedFilters?.paymentStatus || '');
  const [amount, setAmount] = useState(
    savedFilters?.amount || {
      min: '',
      max: ''
    }
  );
  const [recieivedAtDate, setRecieivedAtDate] = useState(savedFilters?.createdAt?.to || '');
  const [recieivedFromDate, setRecieivedFromDate] = useState(savedFilters?.createdAt?.from || '');
  const [permission, setPermission] = useState(savedFilters?.sort || '');

  const getAppliedFilters = () => {
    const chips = [];
    if (selectedOptions?.length) {
      const replacedStatuses = selectedOptions?.map(replaceStatus);
      chips.push({ label: `Status: ${replacedStatuses?.join(', ')}`, key: 'status' });
    }
    if (amount?.min && amount?.max)
      chips.push({ label: `Amount: ${amount?.min} - ${amount?.max}`, key: 'amount' });
    if (recieivedAtDate && recieivedFromDate)
      chips.push({
        label: `Received At: ${formatDateOnly(recieivedFromDate)} - ${formatDateOnly(
          recieivedAtDate
        )}`,
        key: 'createdAt'
      });
    if (permission)
      chips.push({
        label: `Sort: ${permission === 'Descending' ? 'New to Old' : 'Old to New'}`,
        key: 'sort'
      });
    if (byEmail) chips.push({ label: 'Sent by Email', key: 'sendByEmail' });
    if (byPaymentStatus)
      chips.push({
        label: byPaymentStatus,
        key: 'paymentStatus'
      });
    return chips;
  };
  const updateFilters = () => {
    const createdAt =
      recieivedAtDate && recieivedFromDate
        ? { to: recieivedAtDate, from: recieivedFromDate }
        : { to: '', from: '' };
    const amountObj =
      amount?.min && amount?.max ? { min: amount?.min, max: amount?.max } : { min: '', max: '' };
    const filter = {
      search: searchedData && searchedData !== '' && searchedData !== 'undefined' ? true : false,
      filter:
        selectedOptions.length > 0 ||
        byEmail ||
        (amount?.min && amount?.max) ||
        (recieivedAtDate && recieivedFromDate) ||
        byPaymentStatus !== '' ||
        permission !== ''
          ? true
          : false,
      sort: permission,
      paymentStatus: byPaymentStatus,
      createdAt,
      amount: amountObj,
      sendByEmail: byEmail,
      status: selectedOptions
    };

    dispatch(get_fitered_obj(filter));
    setGetDocument(filter);
  };
  const handleChipDelete = (key) => {
    switch (key) {
      case 'status':
        setSelectedOptions([]);
        break;
      case 'amount':
        setAmount({ min: '', max: '' });
        break;
      case 'createdAt':
        setRecieivedAtDate('');
        setRecieivedFromDate('');
        break;
      case 'sort':
        setPermission('');
        break;
      case 'sendByEmail':
        setByEmail(false);
        break;
      case 'paymentStatus':
        setByPaymentStatus('');
        break;
      default:
        break;
    }
    updateFilters();
  };
  useEffect(() => {
    updateFilters();
  }, [
    searchedData,
    selectedOptions,
    byEmail,
    amount,
    recieivedAtDate,
    recieivedFromDate,
    permission,
    byPaymentStatus
  ]);
  const getbatches = async () => {
    const batches = await getAllBatches(companyId);
    if (batches?.status === 200) {
      setBatchDocumentList(batches?.data?.payload?.data || []);
    } else if (batches?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
  };
  const LoadBatchDocs = () => {
    setBatchDocsLoading(true);
    getbatches();
    setBatchDocsLoading(false);
  };
  useEffect(() => {
    const Data = batchDocumentList?.map((item) => ({
      id: item?.id,
      file: item?.fileName || '-',
      noOfInvoices: item?.invoices || '-',
      date: formatDate(item?.createdAt)
    }));
    setBatchDocs(Data);
  }, [batchDocumentList]);
  const Tabs = {
    activeTab: activeTab,
    documentChildActiveTab: childActiveTab,
    IntegrationChildActiveTab: integrationChildTab
  };

  const isConstructionCompany = useSelector(
    (state) => state?.userInfo?.userInfo?.user?.constructionCompany
  );
  const cognitoUsername = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.accessToken?.payload?.username
  );

  const allQueuesData = async (key) => {
    setLoading(true);
    const result = await getSelectedDocs(key || searchProducts, getDocumentRef.current, callSource);
    dispatch(get_queue(result?.data?.payload?.data));
    if (getDocumentRef?.current?.search) {
      if (result?.status === 200) {
        if (!isEmpty(result?.data?.payload?.data)) {
          setFilteredData(result?.data?.payload?.data?.documents);
        }
        setRecordExist(true);
        sessionStorage.setItem('searchedKey', key || searchProducts);
        setSearchProducts(key || searchProducts);
      } else {
        showToast(
          result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
          'error'
        );
      }
    }
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
    setCheckedIds([]);
    setLoading(false);
  };

  const intervalCall = async () => {
    const result = await getSelectedDocs(searchProducts, getDocumentRef.current, callSource);
    if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    }
    dispatch(get_queue(result?.data?.payload?.data));
  };

  useEffect(() => {
    if (getDocumentRef.current && !isEqual(getDocumentRef.current, getDocument)) {
      getDocumentRef.current = getDocument;
      allQueuesData();
    }
  }, [getDocument]);
  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      const interval = setInterval(() => {
        intervalCall();
        getbatches();
      }, 30000);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    dispatch(get_tabs(Tabs));
  }, [activeTab, childActiveTab, integrationChildTab]);

  useEffect(() => {
    setActiveTab(parentActiveTab || parentTabs[0]?.name);
    setChildActiveTab(globalActiveTab?.documentChildActiveTab || childTabs[0]?.name);
    setIntegrationChildTab(globalActiveTab?.IntegrationChildActiveTab || integrationTabs[0]?.name);
  }, [
    parentActiveTab,
    globalActiveTab?.documentChildActiveTab,
    globalActiveTab?.IntegrationChildActiveTab
  ]);

  const handleCreateCSV = async (e) => {
    e.preventDefault();
    setCreateCsvLoading(true);
    const params = {
      documentIds: checkedIds
    };
    const response = await createCsv(params);
    if (response?.status === 200) {
      showToast(response?.data?.metadata?.message, 'success');
      setCheckedIds([]);
      allQueuesData();
      setCreateCsvLoading(false);
    } else if (response?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(response?.data?.metadata?.message, 'error');
    }
    setCreateCsvLoading(false);
  };

  let SyncArray;
  if (isConstructionCompany) {
    SyncArray = serviceAndEquipmentPermission
      ? [...constructionData, 'equipment', 'serviceCode', 'glDivision']
      : constructionData;
  } else {
    SyncArray = serviceAndEquipmentPermission
      ? serviceData
      : ['vendors', 'accounts', 'purchaseOrder', 'employees', 'paid', 'glDivision'];
  }

  const handleSyncErp = async (integtaion, selectedValue) => {
    setErpLoading(true);
    sessionStorage.setItem('erpType', erpType);
    let params = {
      data: selectedValue || SyncArray
    };

    const result = await syncData(params);
    if (result?.status === 200) {
      const getUserresult = await getUserInfo(cognitoUsername);
      if (getUserresult?.status === 200) {
        dispatch(user_information(getUserresult?.data?.payload?.data));
      }
      showToast(result?.data?.metadata?.message, 'success');
      setErpLoading(false);
    } else if (result?.response?.status === 401) {
      sessionStorage.clear();
      navigate('/');
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setErpLoading(false);
  };
  const InitialErpCall = async () => {
    if (
      (userDetails?.activeIntegration && userDetails?.activeIntegration !== '') ||
      erpType === 'RentalMan'
    ) {
      sessionStorage.setItem('erpType', erpType);
      let params = {
        data: SyncArray
      };
      const result = await syncData(params);
      if (result?.status === 200) {
        const getUserresult = await getUserInfo(cognitoUsername);
        if (getUserresult?.status === 200) {
          dispatch(user_information(getUserresult?.data?.payload?.data));
        }
      } else if (result?.response?.status === 401) {
        sessionStorage.clear();
        navigate('/');
      }
    }
  };
  useEffect(() => {
    allQueuesData();
    if (userRole !== MANAGER && userRole !== CLEVEL) {
      InitialErpCall();
      LoadBatchDocs();
    }
  }, []);
  const handleClear = () => {
    setSearchProducts('');
    setFilteredData([]);
    setSelectedOptions([]);
    setByEmail(false);
    setAmount({
      min: '',
      max: ''
    });
    setRecieivedAtDate('');
    setRecieivedFromDate('');
    setByPaymentStatus('');
    setPermission('');
    dispatch(get_fitered_obj(defaultFilter));
    setGetDocument(defaultFilter);
    setRecordExist(false);
  };

  const handleClearAttachmentSearch = () => {
    setIsAttachmentSearched(false);
    setUploadAttachment(true);
    setSearchAttachments('');
    setSearchedResult([]);
    sessionStorage?.setItem('instrumentId', '');
  };
  const handleSearchAttachment = async (id) => {
    setIsLoading(true);
    const result = await searchAttachmentById(id || searchAttachments);
    setSearchedResult([]);
    if (result?.status === 200) {
      setUploadAttachment(false);
      if (!isEmpty(result?.data?.payload?.data)) {
        setSearchedResult(result?.data?.payload?.data);
        setInstumentId(result?.data?.payload?.data?.attachment?.referenceNo);
        sessionStorage?.setItem(
          'instrumentId',
          id || result?.data?.payload?.data?.attachment?.referenceNo
        );
      } else {
        setSearchedResult([]);
        setIsAttachmentSearched(true);
      }
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setIsLoading(false);
  };
  const handleClearErpSearch = () => {
    setIsChequeSearched(false);
    setSearchedCheques([]);
    setChequeNum('');
    sessionStorage?.setItem('chequeNo', '');
  };
  const handleSearchFromErp = async (key) => {
    sessionStorage.setItem('erpType', erpType);
    setIsChequeLoading(true);
    const result = await searchbyChequeNumber(key || chequeNum);
    setSearchedCheques([]);
    if (result?.status === 200) {
      if (!isEmpty(result?.data?.payload?.data)) {
        setSearchedCheques(result?.data?.payload?.data);
        sessionStorage?.setItem('chequeNo', key || chequeNum);
      } else {
        setSearchedCheques([]);
        setIsChequeSearched(true);
      }
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setIsChequeLoading(false);
  };
  const batchDocColumns = [
    {
      field: 'date',
      filterable: false,
      headerName: RECIEVED_AT,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <CallReceivedOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {RECIEVED_AT}
        </div>
      ),
      flex: 1,
      minWidth: 200,
      sortable: true
    },
    {
      field: 'file',
      headerName: FILE_NAME,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <DescriptionOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {FILE_NAME}
        </div>
      ),
      flex: 1,
      minWidth: 240,
      sortable: true
    },
    {
      field: 'noOfInvoices',
      headerName: NO_OF_INVOICES,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <InsertDriveFileOutlined
            sx={{ color: Colors.TOURQUISE, fontSize: '17px', marginRight: '4px' }}
          />
          {NO_OF_INVOICES}
        </div>
      ),
      flex: 1,
      minWidth: 240,
      sortable: true
    },

    {
      field: 'action',
      filterable: false,
      sortable: false,
      headerName: ACTION,
      renderHeader: () => (
        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
          <HighlightAltOutlined
            sx={{
              color: Colors.TOURQUISE,
              fontSize: '17px',
              marginRight: '4px'
            }}
          />
          {ACTION}
        </div>
      ),
      flex: 1,
      minWidth: 100,
      sortable: false,
      disablecolumnmenu: false,
      renderCell: (params) => (
        <MuiModal
          text="View"
          data={params?.row}
          show="batchDocs"
          color={Colors.TOURQUISE}
          btnHeight="2rem"
          width="5rem"
          LargeScreenWidth="61vw"
          XLScreenWidth="61vw"
          getbatches={getbatches}
        />
      )
    },
    {
      field: 'iconButtons',
      sortable: false,
      filterable: false,
      headerName: '',
      minWidth: 120,
      flex: 1,
      disablecolumnmenu: false,
      renderCell: (params) => (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 90
          }}>
          {(userRole === OWNER || userRole === ACCOUNTANT) && (
            <>
              <HtmlTooltip
                title={
                  <Typography color="inherit" sx={{ fontSize: '1em' }}>
                    {DELETE_DOCUMENT}
                  </Typography>
                }>
                <span>
                  <IconButton onClick={(e) => handleOpen(e, params)} aria-label="activity">
                    <DeleteOutlineOutlined
                      sx={{
                        fontSize: '0.8em',
                        color: Colors.RED
                      }}
                    />
                  </IconButton>
                </span>
              </HtmlTooltip>
              <Prompt
                title={DELETE_DOCUMENT}
                description={DELETE_DOCUMENT_MESSAGE}
                leftButton="Cancel"
                rightButton="Delete"
                setOpen={setOpen}
                open={open}
                opacity={true}
                handleClick={handleDeleteDocument}
                loading={deleting}
              />
            </>
          )}
        </div>
      )
    }
  ];
  const deleteDocs = async (id) => {
    setDeleting(true);
    const params = {
      docIds: [id]
    };
    const result = await deleteBatchDoc(params);
    if (result?.status === 200) {
      getbatches();
      const response = await getSelectedDocs(
        searchedData || searchProducts,
        getDocument,
        callSource
      );
      setDeleting(false);
      showToast(result?.data?.metadata?.message, 'success');
      setOpen(false);
      if (response?.response?.status === 401) {
        sessionStorage.clear();
        navigate('/');
      }
      dispatch(get_queue(response?.data?.payload?.data));
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
      setLoading(false);
      setOpen(false);
    }
  };

  const handleDeleteDocument = (e) => {
    e.stopPropagation();
    deleteDocs(rowId);
  };

  const handleOpen = (e, params) => {
    e.stopPropagation();
    setRowId(params?.row?.id);
    setOpen(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ height: '100vh', backgroundColor: 'primary.light' }}>
        <Layout
          activeTab={parentActiveTab}
          setActiveTab={setActiveTab}
          currentTabs={parentTabs}
          filteredData={filteredData}
          recordExist={recordExist}
          handleClear={handleClear}
          path={
            userRole === OWNER ? 'owner' : userRole === ACCOUNTANT ? 'accountant' : 'clientHome'
          }
          handleClearAttachmentSearch={handleClearAttachmentSearch}
          isAttachmentSearched={isAttachmentSearched}
          handleClearErpSearch={handleClearErpSearch}
          getAppliedFilters={getAppliedFilters}
        />
        <Grid container sx={{ paddingX: { lg: '3rem' } }}>
          <Grid
            container
            sx={{
              height: '81vh'
            }}>
            <HeaderSection
              currentTabs={
                parentActiveTab === 'Documents'
                  ? childTabs
                  : parentActiveTab === 'Integration'
                  ? integrationTabs
                  : parentActiveTab === 'Dashboard'
                  ? dashboardTabs
                  : featuresTabs
              }
              setActiveTab={
                parentActiveTab === 'Documents'
                  ? setChildActiveTab
                  : parentActiveTab === 'Integration'
                  ? setIntegrationChildTab
                  : parentActiveTab === 'Dashboard'
                  ? setDashboardChildTabs
                  : setFeaturesChildTab
              }
              activeTab={
                parentActiveTab === 'Documents'
                  ? childActiveTab
                  : parentActiveTab === 'Integration'
                  ? integrationChildTab
                  : parentActiveTab === 'Dashboard'
                  ? dashboardChildTabs
                  : featuresChildTab
              }
              loading={loading}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              splitDocIds={splitDocIds}
              filteredData={filteredData}
              featuresChildTab={featuresChildTab}
              setFilteredData={setFilteredData}
              recordExist={recordExist}
              setRecordExist={setRecordExist}
              intervalCall={intervalCall}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              tab={
                parentActiveTab === 'Documents'
                  ? 'documents'
                  : parentActiveTab === 'Integration'
                  ? 'integration'
                  : parentActiveTab === 'Dashboard'
                  ? 'Dashboard'
                  : parentActiveTab === 'Attachments'
                  ? 'attachments'
                  : 'features'
              }
              integrationChildTab={integrationChildTab}
              handleSyncErp={handleSyncErp}
              erpLoading={erpLoading}
              currentUser={
                userRole === OWNER ? 'Customer' : userRole === ACCOUNTANT ? 'Accountant' : 'Client'
              }
              handleCreateCSV={handleCreateCSV}
              createCsvLoading={createCsvLoading}
              searchProducts={searchProducts}
              setSearchProducts={setSearchProducts}
              dataAccordingTabs={dataAccordingTabs}
              searchAttachments={searchAttachments}
              setSearchAttachments={setSearchAttachments}
              isAttachmentSearched={isAttachmentSearched}
              handleClearAttachmentSearch={handleClearAttachmentSearch}
              searchedResult={searchedResult}
              uploadAttachment={uploadAttachment}
              setUploadAttachment={setUploadAttachment}
              handleSearchAttachment={handleSearchAttachment}
              setChequeNum={setChequeNum}
              handleSearchFromErp={handleSearchFromErp}
              chequeNum={chequeNum}
              isChequeSearched={isChequeSearched}
              searchedCheques={searchedCheques}
              setSearchedCheques={setSearchedCheques}
              handleClearErpSearch={handleClearErpSearch}
              setSyncElement={setSyncElement}
              syncElement={syncElement}
              selectedRowsHasPO={selectedRowsHasPO}
              documentCount={documentCount}
              setGetDocument={setGetDocument}
              allQueuesData={allQueuesData}
              amount={amount}
              setAmount={setAmount}
              recieivedAtDate={recieivedAtDate}
              setRecieivedAtDate={setRecieivedAtDate}
              byEmail={byEmail}
              setByEmail={setByEmail}
              recieivedFromDate={recieivedFromDate}
              setRecieivedFromDate={setRecieivedFromDate}
              permission={permission}
              setPermission={setPermission}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getAppliedFilters={getAppliedFilters}
              handleFilterandSearchClear={handleClear}
              selectedRowsHasReview={selectedRowsHasReview}
              getbatches={getbatches}
              byPaymentStatus={byPaymentStatus}
              setByPaymentStatus={setByPaymentStatus}
            />
            {parentActiveTab === 'Documents' ? (
              <>
                {loading || batchDocsLoading ? (
                  <Grid
                    container
                    sx={{
                      height: '75vh',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <CircularProgress size={150} color="primary" />
                  </Grid>
                ) : childActiveTab !== 'Classify/Split' ? (
                  <>
                    <Grid
                      container
                      spacing={1}
                      sx={{ marginTop: '.3rem', height: '5vh', paddingLeft: '1.8rem' }}>
                      {getAppliedFilters()?.map((chip) => (
                        <Grid item key={chip?.key}>
                          <Chip
                            label={chip?.label}
                            onDelete={() => handleChipDelete(chip?.key)}
                            color="primary"
                            variant="outlined"
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <DataTable
                      resdata={pendingQueues}
                      setCheckedIds={setCheckedIds}
                      checkedIds={checkedIds}
                      filterData={filteredData}
                      toShow="ApiSearch"
                      recordExist={recordExist}
                      currentUser={
                        userRole === OWNER
                          ? 'Customer'
                          : userRole === ACCOUNTANT
                          ? 'Accountant'
                          : 'Client'
                      }
                      isIntegrated={activeIntegration}
                      childActiveTab={childActiveTab}
                      setDataAccordingTabs={setDataAccordingTabs}
                      setSelectedRowsHasPO={setSelectedRowsHasPO}
                      searchProducts={searchProducts}
                      getDocument={getDocument}
                      setSelectedRowsHasReview={setSelectedRowsHasReview}
                      setChildActiveTab={setChildActiveTab}
                    />
                  </>
                ) : (
                  <AdminDataTable
                    apiData={batchDocumentList}
                    rows={batchDocs}
                    setRows={setBatchDocs}
                    columns={batchDocColumns}
                    overlay={true}
                    setSplitDocIds={setSplitDocIds}
                    splitDocIds={splitDocIds}
                    childActiveTab={childActiveTab}
                  />
                )}
              </>
            ) : parentActiveTab === 'Integration' ? (
              <>
                {integrationChildTab === 'My Integration' ? (
                  <MyIntegerations
                    erpLoading={erpLoading}
                    setSyncing={setSyncing}
                    syncing={syncing}
                  />
                ) : integrationChildTab === 'Search Integration' ? (
                  <ErpDocuments
                    isLoading={isChequeLoading}
                    handleSearchFromErp={handleSearchFromErp}
                    setChequeNum={setChequeNum}
                    isChequeSearched={isChequeSearched}
                    searchedResult={searchedCheques}
                  />
                ) : (
                  <ManualSync />
                )}
              </>
            ) : parentActiveTab === 'Dashboard' ? (
              <Dashboard
                documentCount={documentCount}
                setDocumentCount={setDocumentCount}
                setActiveTab={setActiveTab}
                setChildActiveTab={setChildActiveTab}
                setSelectedOptions={setSelectedOptions}
              />
            ) : parentActiveTab === 'Attachments' && !uploadAttachment ? (
              <Attachments
                searchedResult={searchedResult}
                isAttachmentSearched={isAttachmentSearched}
                isLoading={isLoading}
                handleSearchAttachment={handleSearchAttachment}
                setSearchAttachments={setSearchAttachments}
                instrumentId={instrumentId}
                setInstumentId={setInstumentId}
              />
            ) : parentActiveTab === 'Attachments' && uploadAttachment ? (
              <UploadAttachments />
            ) : parentActiveTab === 'Policy' && userRole === OWNER ? (
              <Policy />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default HomePage;
