import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, Typography } from '@mui/material';

import { Colors } from '../config/default';
import { accountDetailsComponent, canvasDetails } from '../constants/appConstants';

export default function AccountDetailList({ Data, annotationType }) {
  const {
    COST_ID,
    COST_TYPE,
    JOB_ID,
    GL_ACCOUNT,
    PHASE,
    PROJECT_ID,
    CLASS,
    EQUIPMENT_NO,
    GL_DIVISION,
    SERVICE_CODE
  } = accountDetailsComponent;
  const { GL_YEAR, GL_PERIOD } = canvasDetails;
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const serviceAndEquipmentPermission = userDetails?.company?.serviceCodeAndEquipmentNo;
  let dataArray = [{ title: GL_ACCOUNT, value: Data?.GlAccount }];
  if (erpType !== 'QuickBooksOnline' && erpType !== 'QuickBooksDesktop') {
    dataArray = [
      ...dataArray,
      { title: JOB_ID, value: Data?.userJobId },
      { title: COST_TYPE, value: Data?.userCostType },
      { title: PHASE, value: Data?.userPhases },
      { title: GL_YEAR, value: Data?.glyear },
      { title: GL_PERIOD, value: Data?.glperiod }
    ];
  }
  if (erpType !== 'QuickBooksOnline' && erpType !== 'DeltekComputerEase') {
    dataArray = [...dataArray, { title: COST_ID, value: Data?.userCostId }];
  }
  if (erpType === 'QuickBooksDesktop') {
    dataArray = [
      ...dataArray,
      { title: JOB_ID, value: Data?.userJobId },
      { title: COST_TYPE, value: Data?.userCostType }
    ];
  }
  if (erpType === 'QuickBooksOnline') {
    dataArray = [
      ...dataArray,
      { title: PROJECT_ID, value: Data?.userJobId },
      { title: CLASS, value: Data?.userCostType }
    ];
  }
  if (
    erpType !== 'DeltekComputerEase' &&
    erpType !== 'QuickBooksOnline' &&
    erpType !== 'QuickBooksDesktop'
  ) {
    dataArray?.splice(
      dataArray?.findIndex((item) => item?.title === GL_YEAR),
      1
    );
    dataArray?.splice(
      dataArray?.findIndex((item) => item?.title === GL_PERIOD),
      1
    );
  }
  if (erpType === 'Foundation' && annotationType === 'Invoice' && serviceAndEquipmentPermission) {
    dataArray = [
      ...dataArray,
      { title: EQUIPMENT_NO, value: Data?.equipmentNo },
      { title: SERVICE_CODE, value: Data?.serviceCode }
    ];
  }
  if (erpType === 'Foundation') {
    dataArray = [...dataArray, { title: GL_DIVISION, value: Data?.glDivision }];
  }
  return (
    dataArray &&
    dataArray?.map((item) => {
      return (
        <Grid
          container
          xs={12}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            color: Colors.TEXTGREY,
            mb: '1.5em'
          }}>
          <Grid xs={4}>
            <Typography sx={{ fontSize: '0.80em' }}>{item?.title}</Typography>
          </Grid>
          <Grid xs={7} sx={{ textAlign: 'right' }}>
            <Typography sx={{ fontSize: '0.80em' }}>{item?.value || '-'} </Typography>
          </Grid>
        </Grid>
      );
    })
  );
}
