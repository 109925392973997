import React, { useRef } from 'react';
import { getPosition } from '../services/common';
import Konva from 'konva';
import { Colors } from '../config/default';

export const findMissingUtil = (first, second) => {
  const spreaded = [...first, ...second];
  return spreaded.filter((el) => {
    return !(first.includes(el) && second.includes(el));
  });
};
export const useDynamicRefs = () => {
  const refs = useRef({});
  const setRef = (key) => {
    if (!refs.current[key]) {
      refs.current[key] = React.createRef();
    }
    return refs.current[key];
  };
  const getRef = (key) => {
    return refs.current[key];
  };
  return [setRef, getRef];
};

const levenshtein = (a, b) => {
  if (a === b) return 0;
  if (!a?.length) return b?.length;
  if (!b?.length) return a?.length;

  let prevRow = Array(b?.length + 1)
    ?.fill(0)
    ?.map((_, i) => i);
  let currRow = Array(b?.length + 1);

  for (let i = 1; i <= a?.length; i++) {
    currRow[0] = i;
    for (let j = 1; j <= b?.length; j++) {
      currRow[j] = Math.min(
        prevRow[j] + 1, // Deletion
        currRow[j - 1] + 1, // Insertion
        prevRow[j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1) // Substitution
      );
    }
    [prevRow, currRow] = [currRow, prevRow];
  }
  return prevRow[b?.length];
};

export function findBestMatch(array, value, returnIndex) {
  if (!Array.isArray(array) || !value) {
    return returnIndex ? -1 : false;
  }
  const sanitize = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
  const sanitizedValue = sanitize(value);
  const threshold = Math.max(3, sanitizedValue?.length * 0.3); // Dynamic threshold
  let closestIndex = -1;
  let minDistance = Infinity;

  for (let i = 0; i < array?.length; i++) {
    const itemName = sanitize(array[i]?.name || array[i]);
    if (!itemName) continue;
    if (sanitizedValue === itemName) return returnIndex ? i : true; // Exact matchs
    const distance = levenshtein(sanitizedValue, itemName);
    if (distance <= threshold) {
      if (!returnIndex) return true;
      if (distance < minDistance) {
        minDistance = distance;
        closestIndex = i;
      }
    }
  }

  return returnIndex ? (minDistance <= threshold ? closestIndex : -1) : false;
}

export function checkObjectValues(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== true) {
      return true;
    }
  }
  return false;
}

export function findVendorIndex(vendorName, glInfo) {
  return findBestMatch(glInfo?.vendor, vendorName, true);
}

export function isvendorValueMatching(array, value) {
  return findBestMatch(array, value, false);
}

export function convertTimeToSeconds(time) {
  const match = time.match(/(\d+)h?:?(\d*)min?:?(\d*)sec?/);
  if (!match) {
    return 0;
  }
  const [, hours, minutes, seconds] = match.map((part) => (part === '' ? 0 : Number(part)));
  return hours * 60 + minutes;
}

export const vendorValueMatchForERP = (glInfo, vendors, erpType, userDetails) => {
  return (
    !isvendorValueMatching(glInfo?.vendor, vendors) &&
    (userDetails?.activeIntegration !== '' || erpType === 'RentalMan')
  );
};

export const getLineItemsCopy = (lineItemsCopy, lineItemsData) => {
  Object.keys(lineItemsData)?.forEach((key) => {
    if (key !== undefined && lineItemsData[key] !== undefined) {
      lineItemsData[key]?.forEach((val, index) => {
        if (val !== undefined && lineItemsCopy[index] !== undefined) {
          const check = lineItemsCopy[index]?.findIndex((item) => item?.name === key);
          if (check !== -1 && lineItemsCopy[index][check]) {
            lineItemsCopy[index][check].value = val === 'N/A' ? '' : val;
          }
        }
      });
    }
  });
};

export const drawLinesByPosition = (
  buttonPosition,
  boxPosition,
  idx,
  dataArray,
  imageDimensions,
  canvasDimensions,
  userDetails,
  getRef,
  showLine,
  confidence,
  BoxReferenceId,
  ButtonReferenceId,
  scrollContainerRef,
  reviewScrollContainerRef
) => {
  var stage;
  let leftSideBarSpace = 450;
  let strokeColor;
  if (confidence >= 99) {
    strokeColor = 'green';
  } else if (confidence >= 85 && confidence < 99) {
    strokeColor = Colors.YELLOW;
  } else {
    strokeColor = Colors.RED;
  }
  stage = new Konva.Stage({
    container: 'myCanvas',
    height: canvasDimensions?.height,
    width: canvasDimensions?.width
  });

  let layer = new Konva.Layer();
  let curve = new Konva.Path({
    data: `
      M ${leftSideBarSpace},${buttonPosition?.top + buttonPosition?.height / 2}
      Q ${(leftSideBarSpace + boxPosition?.left) / 2},${
      buttonPosition?.top + buttonPosition?.height / 2
    }
      ${boxPosition?.left},${boxPosition?.top}
  `,
    stroke: userDetails?.company?.accuracyPointing ? strokeColor : Colors.RED,
    strokeWidth: 3,
    draggable: false
  });

  let rect = new Konva.Rect({
    x: boxPosition?.left,
    y: boxPosition?.top,
    width: dataArray[idx]?.width * imageDimensions?.width || 1,
    height: dataArray[idx]?.height * imageDimensions?.height,
    stroke: userDetails?.company?.accuracyPointing ? strokeColor : Colors.RED,
    strokeWidth: 3,
    draggable: false
  });
  showLine && layer?.add(curve);
  layer?.add(rect);
  stage?.add(layer);
  if (
    !dataArray ||
    dataArray[idx]?.value === '-' ||
    dataArray[idx]?.value === '' ||
    !buttonPosition ||
    !boxPosition
  ) {
    stage = null;
    BoxReferenceId = null;
    ButtonReferenceId = null;
    return;
  }

  function updatePosition() {
    let buttonPos = getPosition('button_' + ButtonReferenceId, getRef);
    let rectPos = getPosition('box_' + BoxReferenceId, getRef);
    let newButtonHeight = buttonPos?.height;
    let newButtonTop = buttonPos?.top;
    let newBoxLeft = rectPos?.left;
    let newBoxTop = rectPos?.top;

    curve.setData(`
            M ${leftSideBarSpace},${newButtonTop + newButtonHeight / 2}
            Q ${(leftSideBarSpace + newBoxLeft) / 2},${newButtonTop + newButtonHeight / 2}
            ${newBoxLeft},${newBoxTop}
        `);
    rect?.setAttr('x', newBoxLeft);
    rect?.setAttr('y', newBoxTop);
    showLine && layer?.add(curve);
    layer?.add(rect);
  }
  const rectPos = getPosition('box_' + BoxReferenceId, getRef);
  const scrollAmount = rectPos?.top - 20;
  scrollContainerRef?.current?.scrollBy({
    top: scrollAmount,
    behavior: 'smooth'
  });

  scrollContainerRef?.current?.addEventListener('scroll', updatePosition);
  reviewScrollContainerRef?.current?.addEventListener('scroll', updatePosition);
};

//line items coding array
export const getLineItemsCoddingArray = (glInfo) => {
  const LineItemsDataArray = [
    { name: 'userCostType', value: glInfo?.costType },
    { name: 'userPhases', value: glInfo?.phase },
    { name: 'userJobId', value: glInfo?.jobIds },
    { name: 'userCostId', value: glInfo?.costIds },
    { name: 'GlAccount', value: glInfo?.glAccount },
    { name: 'serviceCode', value: glInfo?.serviceCode },
    { name: 'equipmentNo', value: glInfo?.equipment },
    { name: 'Product_Code', value: glInfo?.productCode || [] },
    { name: 'glDivision', value: glInfo?.glDivision || [] }
  ];
  return LineItemsDataArray;
};
