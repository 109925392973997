import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material';

import { Colors } from '../config/default';
import AttachmentDropzone from '../components/attachmentDropzone';
import Btn from '../components/button';
import { Close } from '@mui/icons-material';
import CustomSelect from '../components/customSelect';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useToast } from '../toast/toastContext';

import { searchDocumentsbyVendorName, uploadAttachments, getGlInfo } from '../services/services';
import { isEmpty } from 'lodash';
import { truncateFileName } from '../services/common';
import { attachmentPage } from '../constants/appConstants';
import '../../src/attachment.css';
import ImageCard from '../components/imageCard';

export default function UploadAttachments() {
  const {
    CONNECT_ERP,
    NO_DOCUMENTS,
    SELECTED_DOCUMENTS,
    ERROR,
    SEARCH_DOCUMENTS,
    LOADING,
    NO_ATTACHMENTS,
    ATTACHMENTS
  } = attachmentPage;
  const smallToLarge = useMediaQuery('(max-width:2200px)');
  const { showToast } = useToast();
  const [files, setFiles] = useState([]);
  const [instrumentId, setInstumentId] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [documentId, setDocumentId] = useState('');
  const [foundDocument, setFoundDocument] = useState([]);
  const [searching, setSearching] = useState(false);
  const [queue, setQueue] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;
  const getInformation = async () => {
    setLoading(true);
    const res = await getGlInfo(userDetails?.user?.email);
    if (res?.data?.metadata?.status === 'SUCCESS') {
      setVendors(res?.data?.payload?.data?.vendorsList?.map((item) => item?.name));
    }
    setLoading(false);
  };

  useEffect(() => {
    getInformation();
  }, []);
  const [vendor, setVendor] = useState('');
  const handleClose = (event) => {
    const selectedValue = event.target.getAttribute('data-value');
    setVendor(selectedValue);
    setAnchorEl(null);
  };
  const handleSearchDocByVendor = async () => {
    setSearching(true);
    const response = await searchDocumentsbyVendorName(vendor, documentId);
    if (response?.status === 200) {
      setFoundDocument(response?.data?.payload?.data);
      if (isEmpty(response?.data?.payload?.data)) {
        showToast('No document found for the provided criteria', 'error');
      }
    } else {
      showToast(
        response?.response?.data?.metadata?.message || response?.data?.metadata?.message,
        'error'
      );
    }
    setSearching(false);
  };
  const CloseFoundResult = () => {
    setFoundDocument([]);
    setVendor('');
    setDocumentId('');
  };

  const addDocumentToQueue = (doc) => {
    const isDuplicate = queue?.some((item) => item._id === doc._id);
    if (isDuplicate) {
      showToast('Duplicate error: Document already selected.', 'error');
    } else {
      queue.push(doc);
      const updatedFoundDocument = foundDocument?.filter((item) => item?._id !== doc?._id);
      setFoundDocument(updatedFoundDocument);
      if (foundDocument?.length === 1) {
        setFoundDocument([]);
        setVendor('');
        setDocumentId('');
      }
    }
  };
  const handleRemoveFromQueue = (id) => {
    setQueue((prevQueue) => prevQueue?.filter((item) => item._id !== id));
  };
  const removeFile = (fileName) => {
    const filteredFile = files.filter((file) => file.name !== fileName);
    setFiles(filteredFile);
    const updatedInstrumentIds = [...instrumentId];
    updatedInstrumentIds?.splice(
      files?.findIndex((file) => file?.name === fileName),
      1
    );
    setInstumentId(updatedInstrumentIds);
  };
  const UploadAttachment = async () => {
    setUploading(true);
    const result = await uploadAttachments(files, queue, instrumentId, companyId);
    if (result?.status === 200) {
      showToast(result?.data?.metadata?.message, 'success');
      setInstumentId([]);
      setQueue([]);
      setFiles([]);
      setFoundDocument([]);
      setVendor('');
      setDocumentId('');
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setUploading(false);
  };
  const areInstrumentIdsUnique = new Set(instrumentId).size === instrumentId?.length;

  return (
    <Grid
      container
      sx={{
        height: '75vh',
        justifyContent: { xs: 'auto', md: 'space-between', xl: 'flex-start' },
        padding: '1rem 2rem',
        gap: { xs: '1%', lg: 'auto', xl: '1%' }
      }}>
      <Grid
        item
        xs={12}
        md={3.5}
        sx={{
          padding: '1rem',
          backgroundColor: Colors.NAV_BLACK,
          borderRadius: '20px',
          height: smallToLarge ? '73vh' : '55vh',
          justifyContent: 'space-between'
        }}>
        <Typography sx={{ color: Colors.TOURQUISE, mb: '1rem' }}>{ATTACHMENTS}</Typography>
        <AttachmentDropzone setFiles={setFiles} />
        <Grid
          item
          sx={{
            height: smallToLarge ? '67%' : '80%',
            overflowY: 'auto',
            marginBottom: '1rem',
            '&::-webkit-scrollbar': {
              width: '.2em',
              height: '0em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              margin: '1rem 0rem',
              borderRadius: '20px',
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          {isEmpty(files) ? (
            <Grid
              container
              sx={{ justifyContent: 'center', alignItems: 'center', height: 'inherit' }}>
              <p style={{ color: Colors.TEXTGREY }}>{NO_ATTACHMENTS}</p>
            </Grid>
          ) : (
            files?.map((file, index) => {
              return (
                <Box
                  sx={{
                    border: '2px solid #5B5B5B',
                    borderStyle: 'dashed',
                    borderRadius: '20px',
                    padding: '1rem',
                    mb: '.5rem'
                  }}>
                  <Close
                    sx={{ float: 'right', color: Colors.TEXTGREY, cursor: 'pointer' }}
                    onClick={() => removeFile(file?.name)}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: '.5rem'
                    }}>
                    <Grid container item xs={12} sx={{ flexDirection: 'column' }}>
                      <Typography
                        variant="body1"
                        sx={{ color: Colors.WHITE, display: 'flex', mt: '1rem' }}>
                        <div
                          style={{ width: '8rem', color: Colors.TOURQUISE, marginRight: '.3rem' }}>
                          File Name:
                        </div>
                        {truncateFileName(file?.name, smallToLarge ? 19 : 34)}
                      </Typography>
                      <Box sx={{ display: 'flex', mt: '.7rem', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ color: Colors.TOURQUISE, width: '8rem' }}>
                          Instrument ID:
                        </Typography>
                        <input
                          className="instrument_id"
                          style={{
                            border: 'none',
                            color: Colors.WHITE,
                            backgroundColor: Colors.Canvas_BG,
                            padding: '.5rem',
                            borderRadius: '10px',
                            width: '60%'
                          }}
                          onChange={(e) => {
                            const updatedInstrumentIds = [...instrumentId];
                            updatedInstrumentIds[index] = e.target.value;
                            setInstumentId(updatedInstrumentIds);
                          }}
                          value={instrumentId[index] || ''}
                          placeHolder="Enter Unique ID"
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              );
            })
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={3.5}
        sx={{
          backgroundColor: Colors.NAV_BLACK,
          padding: '1rem',
          borderRadius: '20px',
          height: smallToLarge ? '73vh' : '55vh'
        }}>
        <Typography sx={{ color: Colors.TOURQUISE }}>{SEARCH_DOCUMENTS}</Typography>
        <CustomSelect
          disabled={isEmpty(vendors)}
          text="Select Vendor"
          role={vendor}
          handleClose={handleClose}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          options={vendors}
          setRole={setVendor}
          height="2.2rem"
          backgroundColor={Colors.Canvas_BG}
        />

        {loading ? (
          <div
            style={{ padding: 0, fontSize: '13px', color: Colors.TEXTGREY, marginLeft: '.5rem' }}>
            Loading...
          </div>
        ) : isEmpty(vendors) ? (
          <div style={{ padding: 0, fontSize: '13px', color: Colors.RED, marginLeft: '.5rem' }}>
            {CONNECT_ERP}
          </div>
        ) : (
          ''
        )}
        <Grid
          container
          item
          sx={{ alignItems: 'center', justifyContent: 'space-between', marginTop: '.5rem' }}>
          <input
            className="input-placeholder"
            style={{
              border: 'none',
              borderRadius: '5px',
              color: Colors.TEXTGREY,
              backgroundColor: Colors.Canvas_BG,
              height: '2.2rem',
              padding: '0rem 1rem',
              width: '57%',
              borderRadius: '10px'
            }}
            onChange={(e) => setDocumentId(e.target.value)}
            value={documentId}
            placeHolder="Document No"
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                e.target.tagName === 'INPUT'
                 &&
                vendor !== '' &&
                documentId !== '' 
              ) {
                e.preventDefault();
                handleSearchDocByVendor();
              }
            }}
          />
          <Btn
            loading={searching}
            disabled={
              isEmpty(foundDocument) && (vendor === '' || documentId === '' || isEmpty(vendor))
            }
            text={!isEmpty(foundDocument) ? 'Clear' : 'Find'}
            backgroundColor={!isEmpty(foundDocument) ? Colors.RED : Colors.DEEPBLUE}
            radius="10px"
            color={Colors.WHITE}
            width="30%"
            height="2.2rem"
            onClick={!isEmpty(foundDocument) ? CloseFoundResult : handleSearchDocByVendor}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: smallToLarge ? 'center' : 'flex-start',
            overflowY: 'auto',
            height: smallToLarge ? '76%' : '82%',
            justifyContent: 'flex-start',
            padding: '.5rem 0rem',
            '&::-webkit-scrollbar': {
              width: '.2em',
              height: '0em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              margin: '1rem 0rem',
              borderRadius: '20px',
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          {isEmpty(foundDocument) ? (
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <p style={{ color: Colors.TEXTGREY }}>{NO_DOCUMENTS}</p>
            </Grid>
          ) : (
            <Grid container sx={{ height: 'max-content', gap: '3%' }}>
              {foundDocument?.map((data) => {
                return (
                  <ImageCard
                    data={data}
                    onClick={() => addDocumentToQueue(data)}
                    btnText="Add to Queue"
                    width={270}
                    height={338}
                    variant="body2"
                    fontLength={13}
                    btnColor={Colors.DEEPBLUE}
                    url={data?.convertedS3Keys[0]}
                  />
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={4.5}
        sx={{
          backgroundColor: Colors.NAV_BLACK,
          padding: '1rem',
          borderRadius: '20px',
          height: smallToLarge ? '73vh' : '55vh',
          position: 'relative'
        }}>
        <Typography sx={{ color: Colors.TOURQUISE }}>{SELECTED_DOCUMENTS}</Typography>
        <Grid
          container
          item
          sx={{
            height: smallToLarge ? '60vh' : '50vh',
            overflowY: 'auto',
            gap: '1%',
            '&::-webkit-scrollbar': {
              width: '.2em',
              height: '0em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              margin: '1rem 0rem',
              borderRadius: '20px',
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          {isEmpty(queue) ? (
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p style={{ color: Colors.TEXTGREY }}>{ERROR}</p>
            </Grid>
          ) : (
            <Grid container sx={{ height: 'max-content', gap: '3%' }}>
              {queue?.map((item, i) => {
                return (
                  <ImageCard
                    data={item}
                    btnText="Remove from Queue"
                    onClick={() => handleRemoveFromQueue(item?._id)}
                    width={225}
                    height={338}
                    variant="caption"
                    fontLength={10}
                    btnColor={Colors.RED}
                    url={item?.convertedS3Keys[0]}
                  />
                );
              })}
            </Grid>
          )}
        </Grid>
        <Button
          disabled={
            uploading ||
            isEmpty(files) ||
            isEmpty(queue) ||
            instrumentId?.length !== files?.length ||
            instrumentId?.some((id) => id?.trim() === '' || !areInstrumentIdsUnique)
          }
          sx={{
            color: Colors.WHITE,
            backgroundColor: Colors.DEEPBLUE,
            borderRadius: '10px',
            position: 'absolute',
            bottom: '10px',
            width: '93%',
            '&:hover': {
              background: Colors.DEEPBLUE,
              border: 'none'
            },
            '&:disabled': {
              backgroundColor: '#282828'
            }
          }}
          onClick={UploadAttachment}>
          {uploading ? <CircularProgress size={25} color="inherit" /> : 'Attach'}
        </Button>
      </Grid>
    </Grid>
  );
}
